import * as React from 'react';
import { default as MuiRadioGroup } from '@material-ui/core/RadioGroup';
import { RadioGroupProps, FieldsetProps, LegendProps } from './RadioGroup.types';
import { Typography } from '../Typography';
import { HideOffScreen } from '../HideOffScreen';
import { FormControl } from '../FormControl';
import { FormLabel } from '../FormLabel';

export const RadioGroup = React.forwardRef((
  props: RadioGroupProps,
  ref: React.Ref<unknown>,
) => {
  const { children, hasCustomLegend, isRequired, legendText, legendVariant, requiredLabel, ...other } = props;
  const Fieldset: React.ForwardRefExoticComponent<FieldsetProps> = FormControl;
  const Legend: React.ForwardRefExoticComponent<LegendProps> = FormLabel;

  const setFieldsetLegend = () => (
    <Fieldset component="fieldset">
      <Legend component="legend" required={isRequired}>
        <Typography
          variant={legendVariant}
          component="span"
        >
          {legendText}
          {requiredLabel && <HideOffScreen>{requiredLabel}</HideOffScreen>}        </Typography>
      </Legend>
      <MuiRadioGroup
        ref={ref}
        aria-required={undefined}
        role={undefined}
        aria-label={undefined}
        {...other}
      >
        {children}
      </MuiRadioGroup>
    </Fieldset>
  );

  const noFieldsetLegend = () => (
    <MuiRadioGroup
      ref={ref}
      aria-required={undefined}
      role={undefined}
      aria-label={undefined}
      {...other}
    >
      {children}
    </MuiRadioGroup>
  );

  if (!hasCustomLegend && !legendText) {
    throw new Error('legendText must be provided when setting hasCustomLegend=false');
  }

  if (isRequired && !requiredLabel) {
    throw new Error('requiredLabel must be provided when setting isRequired="true"');
  }

  if (!hasCustomLegend) {
    return setFieldsetLegend();
  }
  return noFieldsetLegend();
});

RadioGroup.defaultProps = {
  hasCustomLegend: false,
  isRequired: false,
};

export default RadioGroup;
