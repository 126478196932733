import * as React from 'react';
import { create, Jss } from 'jss';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import jssRtl from 'jss-rtl';
import memoize from 'lodash/memoize';
import { BbThemeProvider } from '../BbThemeProvider';
import pxToRem from '../../internal/utilities/px-to-rem';
import { ZIndexOptions } from '@material-ui/core/styles/zIndex';

export interface JssThemeProviderProps {
  jssInsertionPoint?: string | HTMLElement;
  theme?: string;
  isRtl?: boolean;
  children: React.ReactNode;
  generateClassName?: ReturnType<typeof createGenerateClassName>;
  /**
   * The type of CSS reset that this theme provider should provide. 'global' (default) will insert a global reset stylesheet,
   * 'scoped' will insert a reset stylesheet scoped to this particular component tree, and 'none' will insert no reset stylesheet.
   */
  cssReset?: 'scoped'|'global'|'none';
  zIndex?: ZIndexOptions;
}

export const getJssInstance = memoize((jssInsertionPoint: JssThemeProviderProps['jssInsertionPoint']) => create({
  plugins: [...jssPreset().plugins, pxToRem(), jssRtl()],
  insertionPoint: jssInsertionPoint,
}));

export const JssThemeProvider: React.FunctionComponent<JssThemeProviderProps> = (props) => {
  const { children, theme, jssInsertionPoint, cssReset, isRtl, generateClassName, zIndex } = props;
  const jss: Jss = React.useMemo(() => getJssInstance(jssInsertionPoint), [jssInsertionPoint]);

  return (
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <BbThemeProvider theme={theme} isRtl={isRtl} zIndex={zIndex}>
        {(!cssReset || cssReset === 'global') &&
          <>
            <CssBaseline />
            {children}
          </>
        }
        {cssReset === 'scoped' &&
          <ScopedCssBaseline>
            {children}
          </ScopedCssBaseline>
        }
        {cssReset === 'none' && children}
      </BbThemeProvider>
    </StylesProvider>
  );
};

export default JssThemeProvider;
