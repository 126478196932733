import * as React from 'react';
import {
  default as MuiTableContainer,
  TableContainerProps,
} from '@material-ui/core/TableContainer';

export const TableContainer = React.forwardRef(
  (props: TableContainerProps, ref: React.Ref<HTMLDivElement>) => (
    <MuiTableContainer ref={ref} {...props} />
  )
);

export default TableContainer;
