import * as React from 'react';
import {
  BreadcrumbsContextProps,
  ButtonProps,
  MenuItemProps,
  MenuProps,
} from './Breadcrumbs.types';

export const BreadcrumbsContext = React.createContext<BreadcrumbsContextProps<
  any,
  any,
  any
> | null>(null);

export function useBreadcrumbsContext(): BreadcrumbsContextProps {
  const context = React.useContext<BreadcrumbsContextProps | null>(BreadcrumbsContext);
  if (!context) {
    throw new Error('useBreadcrumbsContext must be used within BreadcrumbsContextProvider.');
  }
  return context;
}

export function BreadcrumbsContextProvider<
  TMenuProps extends MenuProps,
  TMenuItemProps extends MenuItemProps,
  TButtonProps extends ButtonProps
>(
  props: React.PropsWithChildren<BreadcrumbsContextProps<TMenuProps, TMenuItemProps, TButtonProps>>
) {
  const { children, ...other } = props;

  const context: BreadcrumbsContextProps<TMenuProps, TMenuItemProps, TButtonProps> = {
    ...other,
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <BreadcrumbsContext.Provider value={{ ...context }}>{children}</BreadcrumbsContext.Provider>
  );
}

export default BreadcrumbsContextProvider;
