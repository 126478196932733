import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '../../styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `2px dashed ${theme.palette.background.b9}`,
      padding: '8px 12px',
      color: theme.palette.text.primary,
      '&:disabled': {
        border: `2px dashed ${
          theme.palette.type === 'light' ? theme.palette.border.dark : theme.palette.border.main
        }`,
        color: theme.palette.text.hint,
      },
      '&:hover, &:focus': {
        border: `2px solid ${theme.palette.background.b10}`,
        backgroundColor: 'transparent',
      },
      '&:active': {
        borderColor: theme.palette.action.active,
      },
    },
  })
);
