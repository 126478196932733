import * as React from 'react';
import { TableCell } from '../TableCell';
import { Tooltip } from '../Tooltip';
import { TableSortLabel } from '../TableSortLabel';
import { SortableTableHeaderCellProps } from './SortableTableHeaderCell.types';
import { ariaDirections, SortableTableContext } from '../SortableTable/SortableTable.types';

const SortableTableHeaderCell: React.FC<SortableTableHeaderCellProps> = (props) => {
  const {
    children,
    columnId,
    id,
    onClick,
    sortLabelProps,
    tableCellProps,
    tooltip,
    tooltipProps,
  } = props;
  const { currentSortColumn, currentSortDirection, onColumnClicked } = React.useContext(SortableTableContext);

  const isSorted = currentSortColumn === columnId;
  const onSortLabelClicked = React.useCallback((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (onClick) {
      onClick(event);
    }
    onColumnClicked(columnId);
  }, [columnId, onColumnClicked, onClick]);

  const label = (
    <TableSortLabel
      active={isSorted}
      direction={isSorted ? currentSortDirection : undefined}
      {...sortLabelProps}
      onClick={onSortLabelClicked}
    >
      {children}
    </TableSortLabel>
  );

  return (
    <TableCell
      role="columnheader"
      id={`${id}`}
      aria-sort={isSorted && currentSortDirection ? ariaDirections[currentSortDirection] : 'none'}
      {...tableCellProps}
    >
      {tooltip ? (
        <Tooltip
          placement="top-start"
          title={tooltip}
          id={`tooltip-${id}`}
          {...tooltipProps}
        >
          {label}
        </Tooltip>
      ) : label}
    </TableCell>
  );
};

export default SortableTableHeaderCell;
