import * as React from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles, Theme } from '../styles';
import { TextField } from '../TextField';
import { ClickAwayListener } from '../ClickAwayListener';
import { Popper } from '../Popper';
import { Paper } from '../Paper';
import { InputAdornment } from '../InputAdornment';
import { IconButton } from '../IconButton';
import { TriangleDown } from '../../internal/icons/small/TriangleDown';
import { Clear } from '../../internal/icons/small/Clear';
import { ComboboxContextProvider, useComboboxContext } from './Combobox.context';
import { defaultComponents } from './Combobox.components';
import type { ComboboxGroup, ComboboxOption, ComboboxProps } from './Combobox.types';

export const useStyles = makeStyles(<
  Option extends ComboboxOption,
  Group extends ComboboxGroup<Option>,
>(theme: Theme) => createStyles({
  container: {
    position: 'relative',
    display: ({ fullWidth }: Partial<ComboboxProps<Option, Group>>) => (fullWidth ? 'block' : 'inline-block'),
  },
  textField: {
    minWidth: ({ minWidth }: Partial<ComboboxProps<Option, Group>>) => minWidth ?? '216px',
  },
  inputWrap: {
    // Positioning context for combobox input
    position: 'relative',
    display: 'flex',
    minHeight: '38px',
  },
  inputAdornment: {
    marginLeft: 'auto',
  },
  inputArrowIcon: {
    color: ({ disabled }: Partial<ComboboxProps<Option, Group>>) => (disabled ? theme.palette.text.disabled : theme.palette.text.primary),
  },
  clearButton: {
    // Inset focus outline
    '&::before': {
      // !important needed to override specificity from focusOutline
      top: '4px !important',
      left: '4px !important',
      right: '4px !important',
      bottom: '4px !important',
    },
  },
  notSearchable: {
    cursor: 'pointer',
  },
  popper: {
    zIndex: theme.zIndex.modal,
    // Match component width (100%) + border width (2px)
    width: ({ fullWidth }: Partial<ComboboxProps<Option, Group>>) => (fullWidth ? 'calc(100% + 2px)' : 'auto'),
    minWidth: ({ fullWidth }: Partial<ComboboxProps<Option, Group>>) => (!fullWidth ? 'calc(100% + 2px)' : undefined),
  },
}));

export const Combobox = <Option extends ComboboxOption, Group extends ComboboxGroup<Option>>() => {
  const {
    comboboxProps,
    components,
    containerRef,
    inputRef,
    menuIsOpen,
    setMenuIsOpen,
    handleOnClick,
    handleOnBlur,
    handleOnFocus,
    handleOnKeyDown,
    hasValue,
    clearValue,
    setHasFocusWithin,
    offScreenAnnouncement,
    hasFocusWithin,
  } = useComboboxContext<Option, Group>();
  const {
    id,
    disabled,
    options,
    value,
    inputValue,
    onChange,
    onKeyDown,
    onBlur,
    onClick,
    onFocus,
    onSearch,
    searchFilter,
    defaultValue,
    floatingLabel,
    InputProps,
    InputLabelProps,
    isMulti,
    isClearable,
    isSearchable,
    closeOnSelect,
    showSelectedOptions,
    strings,
    fullWidth,
    isLoading,
    className,
    containerProps,
    ListboxProps,
    SearchProps,
    minWidth,
    ...other
  } = comboboxProps;
  const { InputWrap, Listbox, OffScreenAnnouncement, Search } = components;
  const { clearButtonLabel } = strings;
  const classes = useStyles({ disabled, fullWidth, minWidth });
  const shrinkLabel = hasValue || (floatingLabel && hasFocusWithin);

  return (
    <>
      <ClickAwayListener onClickAway={() => setMenuIsOpen(false)}>
        <div
          onFocus={() => setHasFocusWithin(true)}
          onBlur={() => setHasFocusWithin(false)}
          {...containerProps}
          // Declare after spread to merge consumer styles with internal styles.
          className={classnames(classes.container, containerProps?.className)}
        >
          <TextField
            id={id}
            inputRef={inputRef}
            className={classnames(classes.textField, className)}
            onKeyDown={handleOnKeyDown}
            onClick={handleOnClick}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            InputProps={{
              ref: containerRef,
              className: classnames(
                classes.inputWrap,
                { [classes.notSearchable]: !isSearchable },
              ),
              inputComponent: InputWrap,
              endAdornment: (
                <InputAdornment className={classes.inputAdornment} position="end">
                  {isClearable && !disabled && hasValue &&
                    <IconButton
                      aria-label={clearButtonLabel}
                      onClick={clearValue}
                      className={classes.clearButton}
                    >
                      <Clear />
                    </IconButton>
                  }
                  <TriangleDown className={classes.inputArrowIcon} />
                </InputAdornment>
              ),
              readOnly: true,
              ...InputProps,
            }}
            InputLabelProps={{
              htmlFor: id,
              id: `${id}-label`,
              shrink: shrinkLabel,
              ...InputLabelProps,
            }}
            floatingLabel={floatingLabel}
            disabled={disabled}
            fullWidth={fullWidth}
            {...other}
          />
          <Popper
            open={!disabled && menuIsOpen}
            anchorEl={containerRef.current}
            container={containerRef.current}
            placement="bottom-start"
            role={undefined}
            popperOptions={{ scheduleUpdate: () => true }}
            className={classes.popper}
            modifiers={{
              flip: {
                enabled: true,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'scrollParent',
              },
              offset: {
                offset: '0, 5px',
              },
            }}
          >
            <Paper variant="dropdown">
              {isSearchable && <Search />}
              <Listbox />
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
      <OffScreenAnnouncement type="assertive" message={offScreenAnnouncement} />
    </>
  );
};

const ComboboxWithContext = <
  Option extends ComboboxOption,
  Group extends ComboboxGroup<Option>,
>(props: ComboboxProps<Option, Group>) => {
  const { components, ...other } = props;

  return (
    <ComboboxContextProvider components={defaultComponents(components)} {...other}>
      <Combobox {...other} />
    </ComboboxContextProvider>
  );
};

export default ComboboxWithContext;
