import * as React from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles, Theme } from '../../styles';
import { ListSubheader } from '../../ListSubheader';
import { ComboboxGroup, ComboboxOption, MenuListSubheaderProps } from '../Combobox.types';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '22px',
      padding: '2px 12px',
      fontSize: theme.typography.fontSizeSmall,
      fontWeight: theme.typography.fontWeightSemiBold,
      backgroundColor: theme.palette.background.b4,
      borderTop: `1px solid ${theme.palette.border.main}`,
      borderBottom: `1px solid ${theme.palette.border.main}`,
    },
    list: {
      margin: 0,
      padding: 0,
      '& > li:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

export const MenuListSubheader = <
  Option extends ComboboxOption,
  Group extends ComboboxGroup<Option>
>(
  props: MenuListSubheaderProps<Option, Group>
) => {
  const { className, ...other } = props;
  const classes = useStyles(props);

  return (
    <ListSubheader
      className={classnames(classes.root, className)}
      {...other}
      tabIndex={undefined} // Include after props spread to override
    />
  );
};

export default MenuListSubheader;
