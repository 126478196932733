import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { Palette } from '@material-ui/core/styles/createPalette';

export const InputOverrides = (theme: Theme, palette: Palette): Overrides => {
  const disabledBackgroundColor =
    theme.palette.type === 'light' ? theme.palette.background.b3 : theme.palette.background.paper;
  const defaultBorderColor =
    theme.palette.type === 'light' ? theme.palette.background.b8 : theme.palette.border.main;
  const selectedHoverBorderColor =
    theme.palette.type === 'light' ? theme.palette.action.active : theme.palette.border.dark;

  return {
    MuiInput: {
      root: {
        border: `1px solid ${defaultBorderColor}`,
        borderRadius: theme.shape.borderRadius,
        color: 'currentColor',
        '&:hover': {
          borderColor: selectedHoverBorderColor,
        },
        '&$focused': {
          borderColor: theme.palette.focus.main,
          boxShadow: `inset 0 0 0 1px ${theme.palette.focus.light}`,
          // Needed for high contrast mode
          outline: `1px solid ${theme.palette.highContrastOnly.main}`,
        },
        '&$disabled': {
          backgroundColor: disabledBackgroundColor,
          borderColor: theme.palette.border.main,
          color: theme.palette.text.secondary,
        },
        '&$error': {
          borderColor: theme.palette.error.main,
          '&$focused': {
            borderColor: theme.palette.focus.main,
            boxShadow: `inset 0 0 0 1px ${theme.palette.error.main}`,
          },
        },
      },
      input: {
        '&[type="search"]::-webkit-search-cancel-button': {
          appearance: 'none',
        },
        '&[type="search"]::-ms-clear': {
          display: 'none',
          width: 0,
          height: 0,
        },
      },
      formControl: {
        'label + &': {
          marginTop: undefined,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          color: theme.palette.error.main,
        },
      },
    },
  };
};
