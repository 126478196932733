import * as React from 'react';
import classnames from 'classnames';
import { SwitchProps } from '@material-ui/core/Switch';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { thumbDiameter } from './Switch.overrides';

const CheckmarkIcon: React.FunctionComponent = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M1 4L3 7L7 1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const XIcon: React.FunctionComponent = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M8.00424 2L2.00047 7.9962"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.00381 2L8.00381 8"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checked: {
      backgroundColor: theme.palette.success.main,
      // Needed for high contrast mode
      border: `1px solid ${theme.palette.highContrastOnly.main}`,
      color: theme.palette.background.b1,
    },
    disabled: {
      // This uses a gradient with a hard edge instead of a 2px-wide border so
      // that it's not visible to high-constrast browsers.
      backgroundImage: `radial-gradient(${theme.palette.background.b1}, ${
        theme.palette.background.b1
      } ${thumbDiameter / 2 - 2}px, transparent ${thumbDiameter / 2 - 2}px, transparent)`,
      color: theme.palette.background.b7,
    },
    root: {
      alignItems: 'center',
      borderRadius: thumbDiameter / 2,
      display: 'flex',
      height: thumbDiameter,
      minHeight: thumbDiameter,
      minWidth: thumbDiameter,
      justifyContent: 'center',
      width: thumbDiameter,
    },
    unchecked: {
      backgroundColor: theme.palette.background.b8,
      // Needed for high contrast mode
      border: `1px solid ${theme.palette.highContrastOnly.main}`,
      color: theme.palette.background.b1,
    },
  }),
);

export const SwitchThumb: React.FunctionComponent<Pick<SwitchProps, 'checked' | 'disabled'>> = (
  props,
) => {
  const { checked, disabled } = props;
  const classes = useStyles(props);

  return (
    <div
      className={classnames(classes.root, {
        [classes.checked]: !disabled && checked,
        [classes.disabled]: disabled,
        [classes.unchecked]: !disabled && !checked,
      })}
    >
      {checked ? <CheckmarkIcon /> : <XIcon />}
    </div>
  );
};
