import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '../styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      // Hide the native file input.
      display: 'none',
    },
    promptButtonIcon: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginRight: theme.spacing(1),
    },
  })
);
