import * as React from 'react';
import { default as MuiCircularProgress, CircularProgressProps as MuiCircularProgressProps } from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import { ProgressAriaLabelProps, ProgressLabelProps } from '../Progress.types';
import { Typography } from '../../Typography';
import { getColor, Theme, makeStyles, createStyles } from '../../styles';
import { Check } from '../../../internal/icons/small/Check';
import { Exclamation } from '../../../internal/icons/medium/Exclamation';

export interface BaseCircularProgressProps extends Omit<MuiCircularProgressProps, 'id' | 'ref'> {
  formatPercentage?: (value: number | undefined) => string;
  showPercent?: boolean;
  success?: boolean;
}

export interface CircularProgressAriaLabelProps extends BaseCircularProgressProps, ProgressAriaLabelProps {
}

export interface CircularProgressLabelProps extends BaseCircularProgressProps, ProgressLabelProps {
}

export type CircularProgressProps = CircularProgressAriaLabelProps | CircularProgressLabelProps;

enum CircularProgressSize {
  small = 24,
  medium = 38,
  large = 50,
  xlarge = 76,
}

enum CircularProgressVariant {
  determinate = 'determinate',
  indeterminate = 'indeterminate',
  static = 'static',
}

export const useStyles = makeStyles((theme: Theme) => {
  const progressBorder = theme.palette.type === 'light' ? getColor('border.dark', theme) : getColor('secondary.light', theme);
  return createStyles({
    root: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    secondaryRoot: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    smallSize: {
      width: CircularProgressSize.small,
      height: CircularProgressSize.small,
    },
    mediumSize: {
      width: CircularProgressSize.medium,
      height: CircularProgressSize.medium,
    },
    largeSize: {
      width: CircularProgressSize.large,
      height: CircularProgressSize.large,
    },
    xlargeSize: {
      width: CircularProgressSize.xlarge,
      height: CircularProgressSize.xlarge,
    },
    smallLabel: {
      lineHeight: 1.5,
      fontSize: theme.typography.fontSizeDefault,
      fontWeight: theme.typography.fontWeightSemiBold,
      paddingTop: '12px',
    },
    mediumLabel: {
      lineHeight: 1.5,
      fontSize: theme.typography.fontSizeDefault,
      fontWeight: theme.typography.fontWeightSemiBold,
      paddingTop: '12px',
    },
    largeLabel: {
      lineHeight: 1.5,
      fontSize: theme.typography.fontSizeMedium,
      fontWeight: theme.typography.fontWeightSemiBold,
      paddingTop: '12px',
    },
    xlargeLabel: {
      lineHeight: 1.5,
      fontSize: theme.typography.fontSizeLarge,
      fontWeight: theme.typography.fontWeightRegular,
      paddingTop: '12px',
    },
    circleContainer: {
      position: 'relative',
    },
    backgroundCircle: {
      position: 'absolute',
    },
    circleOutline: {
      stroke: progressBorder,
    },
    circleActive: {
      color: getColor('brand.main', theme),
      position: 'relative',
      left: 0,
      strokeLinecap: 'round',
    },
    errorState: {
      color: theme.palette.error.main,
      position: 'relative',
      left: 0,
    },
    successState: {
      color: theme.palette.success.main,
      position: 'relative',
      left: 0,
    },
    progressValue: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    valueTextMedium: {
      fontSize: theme.typography.fontSizeXSmall,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    valueTextLarge: {
      fontSize: theme.typography.fontSizeSmall,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    valueTextXlarge: {
      fontSize: theme.typography.fontSizeDefault,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    valueWrapper: {
      position: 'relative',
      display: 'inline-flex',
    },
    svg: {
      transformOrigin: 'center',
      borderRadius: '100%',
      animation: '$progress-rotate 4s linear 0s infinite',
    },
    indeterminateSvg: {
      /**
       * Note: the use of color + stroke is intentional.
       * When stroke inherits from color (via currentColor), it allows the browser
       * to correctly override our value when high contrast mode is enabled.
       */
      color: getColor('brand.main', theme),
      stroke: 'currentColor',
      strokeWidth: 16,
      strokeLinecap: 'round',
      strokeDasharray: '45px',
      transition: 'stroke 300ms',
    },
    '@keyframes progress-rotate': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  });
});

const getSize = (size: string | number): number => {
  switch (size) {
    case 'small':
      return CircularProgressSize.small;
    case 'medium':
      return CircularProgressSize.medium;
    case 'large':
      return CircularProgressSize.large;
    case 'xlarge':
      return CircularProgressSize.xlarge;
    default:
      return +size;
  }
};

export const CircularProgress = (
  props: CircularProgressProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const {
    error = false,
    formatPercentage = (value: number) => `${value}%`,
    id,
    minValue,
    maxValue,
    showPercent = false,
    size = CircularProgressSize.large,
    success = false,
    value,
    variant = CircularProgressVariant.indeterminate,
    className,
    ...withAriaProps
  } = props;
  const { ariaLabel, ...withLabelProps } = withAriaProps as CircularProgressAriaLabelProps;
  const { label, labelProps, ...other } = withLabelProps as CircularProgressLabelProps;
  const classes = useStyles(props);
  const spinnerSize = getSize(size);
  const sanitizedValue = value ? Math.min(100, Math.max(0, value || 0)) : value;
  const DEFAULT_THICKNESS = 4;

  if (label && !id) {
    throw new Error(`The id must be supplied when using an on-screen label`);
  }

  const backgroundCircle = (
    <svg
      aria-hidden={true}
      className={classes.backgroundCircle}
      viewBox="0 0 100 100"
    >
      <circle className={classes.circleOutline} cx={50} cy={50} r={49.5} fill="none" />
      <circle className={classes.circleOutline} cx={50} cy={50} r={41} fill="none" />
    </svg>
  );

  const renderProgressOrIcons = () => {
    if (error || success) {
      return (
        <>
          {backgroundCircle}
          <div className={classes.valueWrapper}>
            <MuiCircularProgress
              thickness={DEFAULT_THICKNESS}
              size={spinnerSize}
              className={classnames(
                className,
                {
                  [classes.errorState]: error,
                  [classes.successState]: success,
                },
              )}
              variant={CircularProgressVariant.static}
              value={error ? value : 100}
              aria-valuenow={undefined}
              aria-valuemax={undefined}
              aria-valuemin={undefined}
              aria-valuetext={undefined}
              role={error ? 'alert' : 'status'}
              aria-label={!label ? ariaLabel : undefined}
              aria-labelledby={label ? `circular-progress-text-${id}` : undefined}
              ref={ref}
              {...other}
            />
            {spinnerSize >= CircularProgressSize.medium ? (
              <div className={classes.progressValue}>
                {error ? <Exclamation /> : <Check />}
              </div>
            ) : null}
          </div>
        </>
      );
    }

    if (variant === CircularProgressVariant.determinate && sanitizedValue !== undefined) {
      return (
        <>
          {showPercent && backgroundCircle}
          <div className={classes.valueWrapper}>
            <MuiCircularProgress
              variant={variant}
              aria-label={!label ? ariaLabel : undefined}
              aria-labelledby={label ? `circular-progress-text-${id}` : undefined}
              aria-valuemin={minValue ?? 0}
              aria-valuemax={maxValue ?? 100}
              aria-valuetext={formatPercentage(sanitizedValue)}
              className={classnames(className, classes.circleActive)}
              size={spinnerSize}
              thickness={DEFAULT_THICKNESS}
              value={sanitizedValue}
              ref={ref}
              {...other}
            />
            {spinnerSize >= CircularProgressSize.medium ? (
              <div className={classes.progressValue}>
                <Typography className={classnames(
                  classes.root,
                  {
                    [classes.valueTextMedium]: spinnerSize > CircularProgressSize.small && spinnerSize < CircularProgressSize.large,
                    [classes.valueTextLarge]: spinnerSize > CircularProgressSize.medium && spinnerSize < CircularProgressSize.xlarge,
                    [classes.valueTextXlarge]: spinnerSize >= CircularProgressSize.xlarge,
                  },
                )}
                >{formatPercentage(sanitizedValue)}
                </Typography>
              </div>
            ) : null}
          </div>
        </>
      );
    }

    if (variant === 'static' && sanitizedValue !== undefined) {
      return (
        <>
          {backgroundCircle}
          <div className={classes.valueWrapper}>
            <MuiCircularProgress
              variant={variant}
              aria-valuemin={undefined}
              aria-valuemax={undefined}
              aria-valuetext={undefined}
              aria-valuenow={undefined}
              role={undefined}
              aria-hidden={true}
              className={classes.circleActive}
              size={spinnerSize}
              thickness={DEFAULT_THICKNESS}
              value={sanitizedValue}
              ref={ref}
              {...other}
            />
            {showPercent && spinnerSize >= CircularProgressSize.medium ? (
              <div className={classes.progressValue}>
                <Typography className={classnames(
                  classes.root,
                  {
                    [classes.valueTextMedium]: spinnerSize > CircularProgressSize.small && spinnerSize < CircularProgressSize.large,
                    [classes.valueTextLarge]: spinnerSize > CircularProgressSize.medium && spinnerSize < CircularProgressSize.xlarge,
                    [classes.valueTextXlarge]: spinnerSize >= CircularProgressSize.xlarge,
                  },
                )}
                >{formatPercentage(sanitizedValue)}
                </Typography>
              </div>
            ) : null}
          </div>
        </>
      );
    }

    // Indeterminate variant return
    return (
      <div
        className={classnames(
          className,
          classes.root,
          {
            [classes.smallSize]: spinnerSize <= CircularProgressSize.small,
            [classes.mediumSize]: spinnerSize > CircularProgressSize.small && spinnerSize < CircularProgressSize.large,
            [classes.largeSize]: spinnerSize > CircularProgressSize.medium && spinnerSize < CircularProgressSize.xlarge,
            [classes.xlargeSize]: spinnerSize >= CircularProgressSize.xlarge,
          },
        )}
        role="status"
        aria-label={!label ? ariaLabel : undefined}
        aria-labelledby={label ? `circular-progress-text-${id}` : undefined}
        ref={ref}
        {...other}
      >
        <svg
          className={classes.svg}
          viewBox="-10 -10 220 220"
          focusable="false"
          aria-hidden="true"
        >
          <path
            className={classes.indeterminateSvg}
            d="m100,0a100,100 0 0 1 0,200a100,100 0 0 1 0,-200"
            fill="transparent"
            strokeDasharray={629}
            strokeDashoffset={629}
          />
        </svg>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.circleContainer}>
        {renderProgressOrIcons()}
      </div>
      {label ? (
        <Typography
          component="p"
          id={`circular-progress-text-${id}`}
          variant={spinnerSize > CircularProgressSize.small ? 'h4' : 'h5'}
          className={classnames(
            classes.root,
            {
              [classes.smallLabel]: spinnerSize <= CircularProgressSize.small,
              [classes.mediumLabel]: spinnerSize > CircularProgressSize.small && spinnerSize < CircularProgressSize.large,
              [classes.largeLabel]: spinnerSize > CircularProgressSize.medium && spinnerSize < CircularProgressSize.xlarge,
              [classes.xlargeLabel]: spinnerSize >= CircularProgressSize.xlarge,
            },
          )}
          {...labelProps}
        >
          {label}
        </Typography>
      ) : null}
    </div>
  );
};

const CircularProgressRef = React.forwardRef(CircularProgress) as (
  props: CircularProgressProps & { ref?: React.Ref<HTMLDivElement> }
) => ReturnType<typeof CircularProgress>;

export default CircularProgressRef;
