import * as React from 'react';
import classnames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { ListProps } from '@material-ui/core/List';
import { List } from '../List';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexShrink: 0,
  },
  horizontal: {
    display: 'flex',
    '& li': {
      flexShrink: 0,
    },
  },
}));

export interface NavigationListProps extends ListProps {
  navTagAriaLabel: string;
  orientation?: string;
}

const NavigationList = React.forwardRef((
  props: NavigationListProps,
  ref: React.Ref<HTMLUListElement>,
) => {
  const classes = useStyles(props);
  const { className, navTagAriaLabel, orientation, ...other } = props;

  return (
    <nav aria-label={navTagAriaLabel} className={classnames(classes.root, className)}>
      <List
        disablePadding
        {...other}
        ref={ref}
        className={classnames({
          [classes.horizontal]: orientation === 'horizontal',
          'bb-ui-navigation-list-horizontal': orientation === 'horizontal',
        })}
      />
    </nav>
  );
});

export default NavigationList;
