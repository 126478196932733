import * as React from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { PickerProps, OptionType } from './Picker.types';
import { BasePicker, basePickerDefaultProps } from './BasePicker';
import { withTheme, withStyles } from '@material-ui/core/styles';
import { styles } from './Picker.styles';

const SelectComponent = React.forwardRef((
  props: PickerProps,
  ref: React.Ref<Select<OptionType> | Creatable<OptionType>>
) => {
  return props.isCreatable ?
    <Creatable {...props} ref={ref as React.Ref<Creatable<OptionType>>} /> :
    <Select {...props} ref={ref as React.Ref<Select<OptionType>>} />;
});

export const Picker: React.FunctionComponent<PickerProps> = (props) => {
  return <BasePicker {...props} ChosenSelectComponent={SelectComponent} />
};

Picker.defaultProps = basePickerDefaultProps;

export const PickerStyled = withStyles(styles)(Picker);

export default withTheme(PickerStyled);
