import * as React from 'react';
import { createStyles, makeStyles, Theme } from '../styles';
import { CheckboxProps } from '@material-ui/core/Checkbox';

export type CheckboxIconProps = Pick<
CheckboxProps,
'checked' | 'disabled' | 'indeterminate'
>;

interface IconProps {
  className: string;
}

// The size of these icons must be even to avoid centering problems in certain
// browsers (assuming the overall width of the icon is also even). The problem
// also only sometimes manifests at certain zoom levels.

const CheckIcon: React.FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4L3 7L7 1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const IndeterminateIcon: React.FunctionComponent<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="8"
    height="2"
    viewBox="0 0 8 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0L0 0L0 2H8V0Z"
      fill="currentColor"
    />
  </svg>
);

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root(props: CheckboxIconProps) {
    // Unchecked enabled appearance by default.
    let backgroundColor = theme.palette.background.default;
    let borderColor = theme.palette.border.dark;
    // The *Icon rules below cause the correct icon to display (check or
    // indeterminate).

    let iconColor = theme.palette.background.default;

    if (props.disabled) {
      backgroundColor = theme.palette.background.b3;
      iconColor = theme.palette.background.b6;
      borderColor = theme.palette.secondary.light;
    } else if (props.checked || props.indeterminate) {
      backgroundColor = borderColor = theme.palette.success.main;
      iconColor = theme.palette.success.contrastText!;
    }

    return {
      backgroundColor,
      alignItems: 'center',
      borderRadius: '2px',
      border: `2px solid ${borderColor}`,
      color: iconColor,
      display: 'inline-flex',
      height: '16px',
      justifyContent: 'center',
      width: '16px',
    };
  },

  checkIcon(props: CheckboxIconProps) {
    return props.checked ? {} : { display: 'none' };
  },
  indeterminateIcon(props: CheckboxIconProps) {
    return props.indeterminate ? {} : { display: 'none' };
  },
}));

export const CheckboxIcon: React.FunctionComponent<CheckboxIconProps> = (props) => {
  const classes = useStyles(props);

  return (
    <span className={classes.root} aria-hidden="true">
      <CheckIcon className={classes.checkIcon} />
      <IndeterminateIcon className={classes.indeterminateIcon} />
    </span>
  );
};
