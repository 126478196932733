import { Overrides } from '@material-ui/core/styles/overrides';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { palette } from './palette';
import { rgba } from './utilities';
import { focusOutline } from "./mixins";
import { AvatarOverrides } from '../Avatar/Avatar.overrides';
import { BadgeOverrides } from '../Badge/Badge.overrides';
import { AlertOverrides } from '../Banner/Banner.overrides';
import { ButtonOverrides } from '../Button/Button.overrides';
import { CardOverrides } from '../Card/Card.overrides';
import { CheckboxOverrides } from '../Checkbox/Checkbox.overrides';
import { ChipOverrides } from '../Chip/Chip.overrides';
import { DialogOverrides } from '../Dialog/Dialog.overrides';
import { ExpansionPanelDetailsOverrides } from '../ExpansionPanelDetails/ExpansionPanelDetails.overrides';
import { ExpansionPanelSummaryOverrides } from '../ExpansionPanelSummary/ExpansionPanelSummary.overrides';
import { IconButtonOverrides } from '../IconButton/IconButton.overrides';
import { InputAdornmentOverrides } from '../InputAdornment/InputAdornment.overrides';
import { InputBaseOverrides } from '../InputBase/InputBase.overrides';
import { InputLabelOverrides } from '../InputLabel/InputLabel.overrides';
import { InputOverrides } from '../Input/Input.overrides';
import { LinkOverrides } from '../Link/Link.overrides';
import { LinearProgressOverrides } from '../Progress/LinearProgress/LinearProgress.overrides';
import { ListItemOverrides } from '../ListItem/ListItem.overrides';
import { ListItemTextOverrides } from '../ListItemText/ListItemText.overrides';
import { MenuOverrides } from '../Menu/Menu.overrides';
import { OutlinedInputOverrides } from '../OutlinedInput/OutlinedInput.overrides';
import { RadioOverrides } from '../Radio/Radio.overrides';
import { SliderOverrides } from '../Slider/Slider.overrides';
import { SnackbarOverrides } from '../Snackbar/Snackbar.overrides';
import { StepConnectorOverrides } from '../Stepper/StepConnector/StepConnector.overrides';
import { StepContentOverrides } from '../Stepper/StepContent/StepContent.overrides';
import { StepperOverrides } from '../Stepper/Stepper.overrides';
import { SvgIconOverrides } from '../SvgIcon/SvgIcon.overrides';
import { SwitchOverrides } from '../Switch/Switch.overrides';
import { TableOverrides } from '../Table/Table.overrides';
import { TabOverrides } from '../Tab/Tab.overrides';
import { TabsOverrides } from '../Tabs/Tabs.overrides';
import { ToolbarOverrides } from '../Toolbar/Toolbar.overrides';
import { TooltipOverrides } from '../Tooltip/Tooltip.overrides';

export function getOverrides(theme: Theme): Overrides {
  const arrowColor = theme.palette.type === 'light' ? theme.palette.text.primary : theme.palette.background.b10;

  return {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: '100%',
        },
        body: {
          color: theme.palette.text.primary,
          fontFamily: theme.typography.fontFamily,
          fontSize: '14px',
          fontWeight: theme.typography.fontWeightRegular,
          lineHeight: 1.5,
        },
      },
    },
    MuiButtonBase: {
      root: {
        '&$focusVisible': {
          boxShadow: undefined,
          '&': focusOutline(theme, {
            colorInside: theme.palette.focus.light,
            colorOutside: theme.palette.focus.main,
            position: 'inset',
          }),
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: theme.palette.background.b1,
      },
    },
    MuiPopover: {
      paper: {
        border: `1px solid ${theme.palette.border.main}`,
        boxShadow: `${rgba(palette.background.b9, 0.075)} 0px 0px 0px 4px`,
      },
    },
    MuiFormLabel: {
      root: {
        color: undefined,
        fontSize: theme.typography.fontSizeDefault,
        fontWeight: theme.typography.fontWeightSemiBold,
        lineHeight: 1.5,
        marginBottom: '6px',
        '&$error': {
          color: theme.palette.text.primary,
        },
        '&$disabled': {
          color: 'currentColor',
        },
      },
      asterisk: {
        color: theme.palette.error.main,
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          fontStyle: 'italic',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '-12px',
      },
      label: {
        fontSize: theme.typography.fontSizeDefault,
        lineHeight: 1.3,
      },
    },
    MuiSelect: {
      select: {
        width: '100%',
        height: undefined,
        minHeight: '38px',
        paddingTop: '9px',
        paddingBottom: '8px',
        '&:focus': {
          backgroundColor: undefined,
        },
        '&$outlined': {
          minHeight: '38px',
        },
        'select&': {
          minHeight: '36px',
        },
      },
      outlined: {
        width: '100%',
      },
      selectMenu: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '36px',
        paddingTop: '1px',
        paddingBottom: 0,
        '&$outlined': {
          minHeight: '38px',
        },
      },
      icon: {
        right: '6px',
        width: '18px',
        fill: arrowColor,
        color: undefined,
      },
    },
    MuiList: {
      padding: {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    },
    MuiListItemIcon: {
      root: {
        marginRight: undefined,
        minWidth: undefined,
        color: theme.palette.text.secondary,
      },
    },
    MuiListSubheader: {
      root: {
        fontWeight: theme.typography.fontWeightSemiBold,
        color: 'inherit',
        lineHeight: 1.5,
        minHeight: '45px',
        display: 'flex',
        alignItems: 'center',
      },
      gutters: {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
    },
    MuiTypography: {
      h1: theme.typography.h1,
      h2: theme.typography.h2,
      h3: theme.typography.h3,
      h4: theme.typography.h4,
      h5: theme.typography.h5,
      h6: theme.typography.h6,
      body1: theme.typography.body1,
      body2: theme.typography.body2,
      subtitle1: theme.typography.subtitle1,
      subtitle2: theme.typography.subtitle2,
      button: theme.typography.button,
      caption: theme.typography.caption,
      overline: theme.typography.overline,
    },
    MuiDivider: {
      middle: {
        marginLeft: '12px',
        marginRight: '12px',
      },
    },
    MuiTouchRipple: {
      // Hide the ripple when the user hovers-- we only want to show ripples on
      // clicks and taps.

      childPulsate: {
        backgroundColor: 'transparent',
      },
    },
    ...AlertOverrides(theme),
    ...AvatarOverrides(theme),
    ...BadgeOverrides(theme),
    ...ButtonOverrides(theme),
    ...CardOverrides(theme),
    ...CheckboxOverrides(theme),
    ...ChipOverrides(theme),
    ...DialogOverrides(theme),
    ...ExpansionPanelDetailsOverrides(theme),
    ...ExpansionPanelSummaryOverrides(theme),
    ...IconButtonOverrides(theme),
    ...InputOverrides(theme, palette),
    ...InputAdornmentOverrides(theme),
    ...InputBaseOverrides(theme, palette),
    ...InputLabelOverrides(theme),
    ...LinearProgressOverrides(theme),
    ...LinkOverrides(theme),
    ...ListItemOverrides(theme),
    ...ListItemTextOverrides(theme),
    ...MenuOverrides(theme),
    ...OutlinedInputOverrides(theme),
    ...RadioOverrides(theme),
    ...SliderOverrides(theme),
    ...SnackbarOverrides(theme),
    ...StepConnectorOverrides(theme),
    ...StepContentOverrides(theme),
    ...StepperOverrides(theme),
    ...SvgIconOverrides(theme),
    ...SwitchOverrides(theme),
    ...TabOverrides(theme),
    ...TableOverrides(theme),
    ...TabsOverrides(theme),
    ...ToolbarOverrides(theme),
    ...TooltipOverrides(theme),
  };
}
