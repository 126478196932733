import * as React from 'react';
import { SnackbarProvider as BaseSnackbarProvider, SnackbarProviderProps as BaseSnackbarProviderProps } from 'notistack';
import { PaperProps as MuiPaperProps } from '@material-ui/core/Paper';
import { useTheme, useMediaQuery } from '../styles';
import { useStyles } from './SnackbarProvider.styles';

export interface SnackbarProviderProps extends BaseSnackbarProviderProps {
  variant: BaseSnackbarProviderProps['variant'];
  PaperProps?: Partial<MuiPaperProps>;
}

/**
 * Bb customized SnackbarProvider from the 3rd-party library that allows for stackable snackbars.
 * https://iamhosseindhv.com/notistack
 */
export const SnackbarProvider = React.forwardRef(
  (props: SnackbarProviderProps, ref: React.Ref<unknown>) => {
    const theme = useTheme();
    const isLargeBreakpoint = useMediaQuery(theme.breakpoints.up('md'));
    const { snackbarContentRoot } = useStyles(props);

    return (
      <BaseSnackbarProvider
        maxSnack={1}
        anchorOrigin={
          {
            vertical: isLargeBreakpoint ? 'top' : 'bottom',
            horizontal: 'center',
          }
        }
        ref={ref}
        dense={!isLargeBreakpoint}
        hideIconVariant={true}
        ContentProps={{
          className: snackbarContentRoot,
          role: null,
          "aria-describedby": null,
        } as any}
        {...props}
      />
    );
  },
);

export default SnackbarProvider;
