import * as React from 'react';
import { default as MuiCheckbox, CheckboxProps } from '@material-ui/core/Checkbox';
import { CheckboxIcon } from './CheckboxIcon';

export interface InputPropsWithCustomProps extends React.InputHTMLAttributes<HTMLInputElement> {
  // Accommodate any additional custom props coming from the `inputProps` prop
  [customProp: string]: unknown;
}

export interface CheckboxPropsModified extends Omit<CheckboxProps, 'inputProps'> {
  inputProps?: InputPropsWithCustomProps;
}

export const Checkbox = React.forwardRef(
  (props: CheckboxPropsModified, ref: React.Ref<HTMLButtonElement>) => {
    const { disabled, ...other } = props;

    return (
      <MuiCheckbox
        aria-disabled={undefined}
        checkedIcon={<CheckboxIcon checked={true} disabled={disabled} />}
        icon={<CheckboxIcon disabled={disabled} />}
        indeterminateIcon={<CheckboxIcon indeterminate={true} disabled={disabled} />}
        disabled={disabled}
        ref={ref}
        {...other}
      />
    );
  }
);

export default Checkbox;
