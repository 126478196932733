import React, { useRef, useState } from 'react';
import { Key } from 'ts-key-enum';
import { Box } from '../Box';
import { ClickAwayListener } from '../ClickAwayListener';
import { Listbox } from '../Listbox';
import { ListboxOption } from '../Listbox/components';
import { ListboxOptionProps } from '../Listbox/components/Listbox.Option.types';
import { CircularProgress } from '../Progress';
import { useStyles } from './ListboxDropdown.styles';
import { ListboxDropdownProps } from './ListboxDropdown.types';
import { Typography } from '../Typography';
import { useListboxHandlers } from '../Listbox/useListboxHandlers';
import { FloatingDropdown, InlineDropdown } from './components';

const ERROR_OPTION = { id: 'error' };
type ErrorOption = typeof ERROR_OPTION;

const DEFAULT_OPTION = { id: 'default' };
type DefaultOption = typeof DEFAULT_OPTION;

export const ListboxDropdown = <Option,>(
  props: ListboxDropdownProps<Option>,
): React.ReactElement | null => {
  const {
    id,
    strings,
    renderInput,
    variant = 'floating',
    options = null,
    open,
    loading = false,
    error = false,
    openOnFocus = false,
    closeOnSelect = true,
    keepSelectedOption = true,
    onOptionSelected,
    onDefaultOptionSelected,
    onErrorOptionSelected,
    renderEmptyState,
    renderErrorState,
    ClickAwayListenerProps,
    ContainerProps,
    InputContainerProps,
    PopperProps,
    BoxProps,
    CircularProgressProps,
    SubheaderProps,
    ListboxProps,
    DefaultBoxProps,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [listboxHasFocus, setListboxHasFocus] = React.useState(false);

  const [internalOpen, setInternalOpen] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const [focusedOptionId, setFocusedOptionId] = useState<string>('');
  const selectedOptionRef = useRef<Option | DefaultOption | null>(null);

  const ownInputRef = useRef(null);
  const inputRef: React.MutableRefObject<HTMLInputElement | null> = props.inputRef ?? ownInputRef;

  const Dropdown = variant === 'inline' ? InlineDropdown : FloatingDropdown;

  function setFirstOptionAsFocused(): void {
    if (options?.length && options.length > 0) {
      const firstOptionId = `${id}-option-0`;
      setFocusedOptionId(firstOptionId);
    }
  }

  function openDropdown() {
    setInternalOpen(true);
    setListboxHasFocus(true);
    setFirstOptionAsFocused();
  }

  function closeDropdown() {
    setInternalOpen(false);
    setListboxHasFocus(false);
    setFocusedOptionId('');

    if (!keepSelectedOption) {
      selectedOptionRef.current = null;
    }
  }

  function isOptionSelected(option: Option | DefaultOption | ErrorOption) {
    return selectedOptionRef.current === option;
  }

  function selectOption(option: Option | DefaultOption | ErrorOption) {
    if (keepSelectedOption) {
      selectedOptionRef.current = option;
    }
    if (closeOnSelect) {
      closeDropdown();
    }
    if (option !== DEFAULT_OPTION && option !== ERROR_OPTION) {
      onOptionSelected?.(option as Option);
    }
    if (option === DEFAULT_OPTION) {
      onDefaultOptionSelected?.();
    }
    if (option === ERROR_OPTION) {
      onErrorOptionSelected?.();
    }
  }

  function deselectOption(
    option: Option | DefaultOption | ErrorOption,
    preventMenuClose?: boolean,
  ) {
    selectedOptionRef.current = null;
  }

  function updateFocusedOption(elem: HTMLLIElement | null): void {
    const elemId = elem?.getAttribute('id');
    if (elemId) {
      setFocusedOptionId(elemId);
    }
  }

  function isOptionFocused(optionId: string): boolean {
    return optionId === focusedOptionId;
  }

  const {
    listRef,
    handleOnMouseUp: handleOptionOnClick,
    handleOnKeyDown: handleOptionOnKeyDown,
  } = useListboxHandlers<Option, DefaultOption>({
    hasFocus: listboxHasFocus,
    isOptionSelected,
    selectOption,
    deselectOption,
    onEscape() {
      closeDropdown();
      inputRef?.current?.focus();
    },
    onTab() {
      closeDropdown();
    },
    updateFocusedOption,
  });

  function handleListboxOptionOnKeyDown(
    event: React.KeyboardEvent<HTMLLIElement>,
    option: Option | DefaultOption | ErrorOption,
  ) {
    if (event.key === Key.Enter) {
      // Ignore enter because the MUI menu item click event handles this
      return;
    }

    handleOptionOnKeyDown(event, option);
  }

  function handleOnClickAway(event: React.MouseEvent<Document, MouseEvent>) {
    closeDropdown();

    ClickAwayListenerProps?.onClickAway(event);
  }

  function handleInputOnTextChange(text: string) {
    setInternalOpen(text !== '');
    setFocusedOptionId('');
    setListboxHasFocus(false);
  }

  function handleInputOnKeyDown(
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) {
    switch (event.key) {
      case Key.ArrowDown:
        if (!loading) {
          openDropdown();
        }
        event.preventDefault();
        break;
      case Key.Escape:
        if (internalOpen) {
          closeDropdown();
          event.preventDefault();
        }
        break;
      case Key.Tab:
        if (event.shiftKey) {
          setInternalOpen(false);
        }
    }
  }

  function handleInputOnFocus(event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) {
    if (openOnFocus) {
      setInternalOpen(true);
    }
    setFocusedOptionId('');
    setListboxHasFocus(false);
  }

  function handleInputOnBlur(event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) {
    if (
      !listboxHasFocus &&
      event.relatedTarget instanceof Node &&
      !containerRef.current?.contains(event.relatedTarget)
    ) {
      setFocusedOptionId('');
      setInternalOpen(false);
    }
  }

  const classes = useStyles();

  const isOpen = open || loading || internalOpen;

  const shouldRenderErrorState = !loading && error && !!renderErrorState;
  const shouldRenderEmptyState =
    !loading && !error && options && options.length === 0 && !!renderEmptyState;
  const shouldRenderOptions = !loading && !error && options && options.length > 0;
  const shouldRenderDefaultAction = shouldRenderOptions && !!strings.defaultActionText;
  const shouldRenderListbox = shouldRenderErrorState || shouldRenderOptions;
  const shouldRenderSubheader =
    shouldRenderOptions && !!(strings.subheaderText && strings.subheaderText?.length > 0);

  const loadingId = `${id}-loading`;
  const emptyId = `${id}-empty`;
  const circularProgressId = `${id}-circular-progress`;
  const subheaderId = `${id}-subheader`;
  const listboxId = `${id}-listbox`;
  const listBoxOptionId = `${id}-option-`;
  const errorId = `${listBoxOptionId}${ERROR_OPTION.id}`;
  const defaultOptionId = `${listBoxOptionId}${DEFAULT_OPTION.id}`;

  const getAriaControls = () => {
    if (loading) {
      return loadingId;
    }

    if (shouldRenderEmptyState) {
      return emptyId;
    }

    return listboxId;
  };

  const internalRenderLoading = (): React.ReactNode => {
    if ('renderLoading' in props && typeof props.renderLoading === 'function') {
      return props.renderLoading();
    }

    return (
      <CircularProgress
        id={circularProgressId}
        ariaLabel={strings.loadingAriaLabel}
        size="medium"
        {...CircularProgressProps}
      />
    );
  };

  const internalRenderOption = (option: Option, index: number): React.ReactNode => {
    const optionId = `${listBoxOptionId}${index}`;
    const listboxOptionProps: ListboxOptionProps = {
      id: optionId,
      onClick: () => handleOptionOnClick(option),
      onKeyDown: (event: React.KeyboardEvent<HTMLLIElement>) =>
        handleListboxOptionOnKeyDown(event, option),
      selected: isOptionSelected(option),
      'aria-selected': isOptionFocused(optionId),
    };

    if ('renderOption' in props) {
      return (
        <ListboxOption key={index} {...listboxOptionProps}>
          {props.renderOption(option)}
        </ListboxOption>
      );
    }

    if ('renderCompleteOption' in props) {
      return props.renderCompleteOption(listboxOptionProps, option, index);
    }

    throw new TypeError('You should give either renderOption or renderCompleteOption');
  };

  return (
    <ClickAwayListener {...ClickAwayListenerProps} onClickAway={handleOnClickAway}>
      <div {...ContainerProps} ref={containerRef}>
        <div {...InputContainerProps} ref={setAnchorEl}>
          {renderInput({
            onTextChange: handleInputOnTextChange,
            onKeyDown: handleInputOnKeyDown,
            onFocus: handleInputOnFocus,
            onBlur: handleInputOnBlur,
            inputRef,
            ariaProps: {
              role: 'combobox',
              'aria-autocomplete': 'list',
              'aria-expanded': isOpen,
              'aria-controls': getAriaControls(),
              'aria-activedescendant': focusedOptionId,
            },
          })}
        </div>
        <Dropdown anchorEl={anchorEl} isOpen={isOpen} PopperProps={PopperProps} BoxProps={BoxProps}>
          {loading && (
            <Box id={loadingId} className={classes.item}>
              {internalRenderLoading()}
            </Box>
          )}

          {shouldRenderEmptyState && (
            <Box id={emptyId} className={classes.subheader}>
              {renderEmptyState?.()}
            </Box>
          )}

          {shouldRenderSubheader && (
            <Box className={classes.subheader}>
              <Typography id={subheaderId} variant="h6" component="span" {...SubheaderProps}>
                {strings.subheaderText}
              </Typography>
            </Box>
          )}

          {shouldRenderListbox && (
            <Listbox
              ref={listRef}
              id={listboxId}
              aria-labelledby={error ? errorId : subheaderId}
              {...ListboxProps}
            >
              {shouldRenderErrorState && (
                <ListboxOption
                  id={errorId}
                  onClick={() => handleOptionOnClick(ERROR_OPTION)}
                  onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>) =>
                    handleListboxOptionOnKeyDown(event, ERROR_OPTION)
                  }
                  selected={isOptionSelected(ERROR_OPTION)}
                  aria-selected={isOptionFocused(errorId)}
                  autoFocus
                >
                  {renderErrorState?.()}
                </ListboxOption>
              )}

              {shouldRenderOptions && (
                <>
                  {options?.map((option, index) => internalRenderOption(option, index))}

                  {shouldRenderDefaultAction && (
                    <ListboxOption
                      id={defaultOptionId}
                      onClick={() => handleOptionOnClick(DEFAULT_OPTION)}
                      onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>) =>
                        handleListboxOptionOnKeyDown(event, DEFAULT_OPTION)
                      }
                      selected={isOptionSelected(DEFAULT_OPTION)}
                      aria-selected={isOptionFocused(defaultOptionId)}
                    >
                      <Box {...DefaultBoxProps}>
                        <Typography className={classes.link}>
                          {strings.defaultActionText}
                        </Typography>
                      </Box>
                    </ListboxOption>
                  )}
                </>
              )}
            </Listbox>
          )}
        </Dropdown>
      </div>
    </ClickAwayListener>
  );
};

export default ListboxDropdown;
