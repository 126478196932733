import * as React from 'react';
import { createSvgIcon } from '..';

export const ChevronRight = createSvgIcon(
  {
    LeftToRight: (
      <>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 1l7 7-7 7"
        />
      </>
    ),
  },
  'small'
);
