import * as React from 'react';
import { default as Combobox } from './Combobox';
import {
  CreatableComponents,
  CreatableGroup,
  CreatableOption,
  CreatableComboboxProps,
  CreatableStrings,
} from './CreatableCombobox.types';
import { useCreatable } from './useCreatable';
import { MenuItem } from './components/Combobox.MenuItem';
import { MenuItemProps } from './Combobox.types';
import { useComboboxContext } from './Combobox.context';

export interface CreatableMenuItemProps<
  Option extends CreatableOption,
  Group extends CreatableGroup<Option>
> extends MenuItemProps<Option> {
  strings?: CreatableStrings<Option>;
  components?: Partial<CreatableComponents<Option, Group>>;
  createOptionLabel: CreatableStrings<Option>['createOptionLabel'];
}

export const CreatableMenuItem = <
  Option extends CreatableOption,
  Group extends CreatableGroup<Option>
>(
  props: CreatableMenuItemProps<Option, Group>
) => {
  const { createOptionLabel, components, ...menuItemProps } = props;
  const { option, selected, onKeyDown } = menuItemProps;
  const { searchString, focusSearch } = useComboboxContext<Option, Group>();
  const ComboboxMenuItem = components?.MenuItem ?? MenuItem;
  const CreatableMenuItem = components?.CreatableMenuItem ?? MenuItem;

  if ('__isNew__' in option) {
    const createdOnKeyDown = (event: React.KeyboardEvent<HTMLLIElement>) => {
      if (event.key === ' ') {
        // Focus search input on removeal of created option.
        focusSearch();
      }

      onKeyDown?.(event);
    };

    return !selected ? (
      <CreatableMenuItem replaceContent {...menuItemProps}>
        {createOptionLabel(searchString ?? '')}
      </CreatableMenuItem>
    ) : (
      <ComboboxMenuItem {...menuItemProps} onKeyDown={createdOnKeyDown} />
    );
  }

  return <ComboboxMenuItem {...menuItemProps} />;
};

export const CreatableCombobox = <
  Option extends CreatableOption,
  Group extends CreatableGroup<Option>
>(
  props: CreatableComboboxProps<Option, Group>,
  ref: React.Ref<HTMLDivElement>
) => {
  const { components, ...consumerProps } = props;
  const { createOptionLabel } = consumerProps.strings;
  // eslint-disable-next-line react/no-unstable-nested-components
  const Creatable = (props: CreatableComboboxProps<Option, Group>) => (
    <Combobox {...useCreatable(props)} />
  );

  return (
    <Creatable
      ref={ref}
      components={{
        ...components,
        // eslint-disable-next-line react/no-unstable-nested-components
        MenuItem: (props) => (
          <CreatableMenuItem
            createOptionLabel={createOptionLabel}
            components={components}
            {...props}
          />
        ),
      }}
      {...consumerProps}
    />
  );
};

const CreatableComboboxRef = React.forwardRef(CreatableCombobox) as <
  Option extends CreatableOption,
  Group extends CreatableGroup<Option>
>(
  props: CreatableComboboxProps<Option, Group> & { ref?: React.Ref<HTMLDivElement> }
) => React.ReactElement;

export default CreatableComboboxRef;
