import * as React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import memoize from 'lodash/memoize';
import { createTheme } from '../styles/theme';
import { IBbThemeProviderProps } from './BbThemeProvider.types';
import { ZIndexOptions } from '@material-ui/core/styles/zIndex';

export const getTheme = memoize((direction: 'rtl'|'ltr', paletteType: 'dark'|'light', zIndex?: ZIndexOptions) => createTheme({
  direction,
  palette: {
    type: paletteType,
  },
  zIndex: zIndex ?? {},
}), (direction, paletteType, zIndex) => `${direction}|${paletteType}|${JSON.stringify(zIndex)}`);

export const BbThemeProvider: React.FunctionComponent<IBbThemeProviderProps> = (props) => {
  const { theme, isRtl, children, zIndex } = props;
  const bbTheme = React.useMemo(() => getTheme(isRtl ? 'rtl' : 'ltr', theme === 'dark' ? 'dark' : 'light', zIndex), [isRtl, theme, zIndex]);

  return (
    <MuiThemeProvider theme={bbTheme}>
      {children}
    </MuiThemeProvider>
  );
};
