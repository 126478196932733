import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { default as MuiBadge } from '@material-ui/core/Badge';
import { BadgeProps } from './Badge.types';
import { HideOffScreen } from '../HideOffScreen';

// Our position property affects both dot and root style properties.

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge(props: BadgeProps) {
      const { position, color } = props;
      const styles: CreateCSSProperties<BadgeProps> = {};

      switch (position) {
        case 'corner-top-right':
          break;

        case 'inline':
          styles.marginRight = '4px';
          styles.position = 'static';
          styles.transform = 'none';
          break;

        case 'corner':
        case 'corner-top-left':
        default:
          styles.right = 'auto';
          styles.left = 0;
          break;
      }

      switch (color) {
        case 'indicatorPrimary':
          styles.backgroundColor = theme.palette.indicatorPrimary.dark;
          styles.color = theme.palette.indicatorPrimary.contrastText;
          break;
        case 'indicatorSecondary':
          styles.backgroundColor = theme.palette.indicatorSecondary.main;
          styles.color = theme.palette.indicatorSecondary.contrastText;
          break;
        case 'primary':
          styles.backgroundColor =
            theme.palette.type === 'light'
              ? theme.palette.background.b9
              : theme.palette.background.b10;
          styles.color = theme.palette.brand.contrastText;
          break;
        case 'secondary':
          styles.backgroundColor =
            theme.palette.type === 'light'
              ? theme.palette.background.b4
              : theme.palette.background.b2;
          styles.color = theme.palette.text.secondary;
          break;
        case 'brand':
        default:
          styles.backgroundColor = theme.palette.brand.main;
          styles.color = theme.palette.brand.contrastText;
          break;
      }

      return styles;
    },

    dot(props: BadgeProps) {
      const { position, size } = props;
      const styles: CreateCSSProperties<BadgeProps> = {};

      switch (position) {
        case 'corner-top-right':
          styles.right = 'auto';
          break;

        case 'inline':
          styles.marginRight = '4px';
          styles.position = 'static';
          styles.transform = 'none';
          break;

        case 'corner':
        case 'corner-top-left':
        default:
          // Placement changes by dot size-- see switch statement below.
          styles.right = 'auto';
          styles.transform = 'none';
          break;
      }

      // Dot sizes must incorporate the 2px border around the dot -- e.g. should
      // be 4px more than the radius of the visible dot. The dots are not
      // exactly centered on the top-left corner in all cases. See the Figma
      // library for alignment.

      switch (size) {
        case 'small':
          styles.height = '8px';
          styles.minWidth = '8px';
          if (position === 'corner' || position === 'corner-top-left' || position === undefined) {
            styles.left = '-4px';
            styles.top = '-4px';
          }
          if (position === 'corner-top-right') {
            styles.left = '8px';
          }
          break;

        case 'large':
          styles.height = '16px';
          styles.minWidth = '16px';
          if (position === 'corner' || position === 'corner-top-left' || position === undefined) {
            styles.left = '-8px';
            styles.top = '-8px';
          }
          if (position === 'corner-top-right') {
            styles.left = '21px';
          }
          break;

        case 'medium':
        default:
          styles.height = '12px';
          styles.minWidth = '12px';
          if (position === 'corner' || position === 'corner-top-left' || position === undefined) {
            styles.left = '-7px';
            styles.top = '-7px';
          }
          if (position === 'corner-top-right') {
            styles.left = '11px';
          }
          break;
      }

      return styles;
    },

    root(props: BadgeProps) {
      const { position } = props;
      const styles: CreateCSSProperties<BadgeProps> = {};

      if (position === 'inline') {
        styles.flexDirection = 'row-reverse';
        styles.alignItems = 'center';
        styles.verticalAlign = 'baseline';
      }

      return styles;
    },
  })
);

export const Badge = React.forwardRef((props: BadgeProps, ref: React.Ref<HTMLDivElement>) => {
  const baseClasses = useStyles(props);
  // We need to make sure to pull classes out of the other spread. Otherwise if
  // custom classes are passed in, it will completely override the baseClasses
  // calculated above when passing them into the MuiBadge component below.
  // If props includes classes, the baseClasses above should already merge
  // them with the the ones generated in this module.
  const { badgeContent, variant, size, color, offScreenLabel, classes, ...other } = props;

  // If variant and badgeContent are both unspecified, default the variant to
  // dot. This is a convenience-- you can just write <Badge /> and it gives you
  // a dot; otherwise it wouldn't display anything.

  // eslint-disable-next-line react/no-unstable-nested-components
  const Content = () => (
    <>
      {offScreenLabel ? <span aria-hidden={true}>{badgeContent}</span> : badgeContent}
      {offScreenLabel ? <HideOffScreen component="span">{offScreenLabel}</HideOffScreen> : null}
    </>
  );

  return (
    <MuiBadge
      variant={variant || badgeContent ? variant : 'dot'}
      badgeContent={<Content />}
      ref={ref}
      classes={baseClasses}
      {...other}
    />
  );
});

export default Badge;
