import * as React from 'react';
import {
  default as MuiExpansionPanelSummary,
} from '@material-ui/core/ExpansionPanelSummary';
import { ChevronDown } from '../../internal/icons/small/ChevronDown';
import { ExpansionPanelSummaryProps } from './ExpansionPanelSummary.types';

export const ExpansionPanelSummary: React.FunctionComponent<
ExpansionPanelSummaryProps
> = (props) => {
  const { children, ...otherProps } = props;
  const muiProps = {
    expandIcon: <ChevronDown />,
    ...otherProps,
  };

  return (
    <MuiExpansionPanelSummary {...muiProps}>
      {children}
    </MuiExpansionPanelSummary>
  );
};

export default ExpansionPanelSummary;
