import * as React from 'react';
import classnames from 'classnames';
import { Button } from '../Button';
import { IButtonProps } from '../Button.types';
import { useStyles } from './DashedButton.styles';

export const DashedButton = React.forwardRef((props: IButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const styles = useStyles(props);
  const { className, ...otherProps } = props;
  return (
    <Button
      variant="text"
      ref={ref}
      className={classnames(className, styles.root)}
      {...otherProps}
    />
  );
});

export default DashedButton;
