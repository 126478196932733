import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '../styles';
import { ToggleButtonProps } from './ToggleButton.types';

export const useStyles = makeStyles((theme: Theme) => {
  const rootBorderColor =
    theme.palette.type === 'light' ? theme.palette.border.dark : theme.palette.border.main;

  const rootIconColor =
    theme.palette.type === 'light' ? theme.palette.text.primary : theme.palette.text.secondary;

  const selectedBackgroundColor =
    theme.palette.type === 'light' ? theme.palette.brand.light : theme.palette.brandAlt.main;

  const selectedIconColor =
    theme.palette.type === 'light' ? theme.palette.brand.main : theme.palette.border.dark;

  return createStyles({
    root: {
      backgroundColor: theme.palette.background.b1,
      minWidth: '38px',
      minHeight: '38px',
      padding: '4px',
      border: ({ variant }: ToggleButtonProps) => {
        return variant === 'standalone' ? 'transparent' : `1px solid ${rootBorderColor}`;
      },
      color: rootIconColor,
      '&:hover': {
        backgroundColor: theme.palette.background.b3,
        border: `1px solid${theme.palette.background.b8}`,
      },
      '&[disabled]': {
        border: ({ variant }: ToggleButtonProps) => {
          return variant === 'standalone'
            ? 'transparent'
            : `1px solid ${theme.palette.action.disabled}`;
        },
      },
    },

    selected: {
      '&$root': {
        backgroundColor: selectedBackgroundColor,
        border: `1px solid${theme.palette.brand.main}`,
        '&:focus, &:hover': {
          backgroundColor: selectedBackgroundColor,
        },
      },
      boxSizing: 'border-box',
      width: '37px',
      height: '37px',
      '& svg': {
        color: selectedIconColor,
      },
    },
  });
});
