import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const LinkOverrides = (theme: Theme): Overrides => ({
  MuiLink: {
    root: {
      position: 'relative',
      color: theme.palette.link.active,
      '&:active, &:hover, &:active:hover': {
        color: theme.palette.link.hover,
      },
      /*
      This does not use the focusOutline mixin (in styles/mixins.ts) because
      that uses a pseudoelement for styling that does not handle a link spanning
      multiple lines properly--the outline doesn't follow a second line
      properly.
      */
      '&:focus': {
        color: theme.palette.link.active,
        outline: 'none',
      },
      '&$focusVisible': {
        outline: `1px solid ${theme.palette.focus.main}`,
        boxShadow: `0 0 0 1px ${theme.palette.focus.light}`,
        borderRadius: '2px',
      },
      '&[disabled]': {
        color: theme.palette.link.disabled,
        pointerEvents: 'none',
        cursor: 'default',
        textDecoration: 'none',
      },
    },
    button: {
      verticalAlign: undefined,
      fontSize: 'inherit',
      '&$focusVisible': {
        outline: `2px solid ${theme.palette.focus.main}`,
      },
    },
  },
});
