import { AxiosResponse } from 'axios';
import { useEffect, useState, useCallback } from 'react';
import { IUseTenantFeatureFlagsResults,
  IUseTenantFeatureFlagsProps,
  IFeatureFlagValueData,
  IHashFlagValues,
  ITenantFeatureFlagValues,
  IFlagValueChange,
  IFlagDefinitionChange }
  from './use-tenant-feature-flags.types';
import { fetchAllFlagsData, putFlagValueData, patchFlagDefinitionData } from '../common/http';
import { FeatureFlagVisibility } from '../common/common.types';
import { FeatureFlagDefinitionData } from '../feature-flag-definition/use-feature-flag-definitions.types';

const getFeatureFlagDefinitions = async (apiBaseUrl: string, accessToken: string) => {
  const apiPath = `${apiBaseUrl}/config/privilege/api/v1/featureFlags/definitions`;
  const flagDefinitions = await fetchAllFlagsData(apiPath, accessToken);
  return flagDefinitions as FeatureFlagDefinitionData[];
};

const getFeatureFlagValues = async (apiBaseUrl: string, accessToken: string, tenantId: string) => {
  const apiPath = `${apiBaseUrl}/config/api/v1/tenants/${tenantId}/featureFlags/scopes/Tenant`;
  const flagValues = await fetchAllFlagsData(apiPath, accessToken) as IFeatureFlagValueData[];
  const hashFlagValues: IHashFlagValues = {};
  flagValues.forEach(value => hashFlagValues[value.flagKey] = value);
  return hashFlagValues;
};

/**
 * React hook to create a custom type mixing feature flag value and definition information
 */
export function useTenantFeatureFlags(props: IUseTenantFeatureFlagsProps): IUseTenantFeatureFlagsResults {
  const { apiBaseUrl, accessToken, tenantId } = props;
  const [data, setResults] = useState<ITenantFeatureFlagValues[]>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  // Flag Value Change , One change at a time supported
  const [flagValueChange, setFlagValueChange] = useState<IFlagValueChange>();
  const [flagValueLoading, setFlagValueLoading] = useState(false);
  const [flagValueError, setFlagValueError] = useState<Error>();
  const [flagValueResponse, setFlagValueResponse] = useState<AxiosResponse>();

  // Flag Definition Change, One change at a time supported
  const [flagDefinitionChange, setFlagDefinitionChange] = useState<IFlagDefinitionChange>();
  const [flagDefinitionLoading, setFlagDefinitionLoading] = useState(false);
  const [flagDefinitionError, setFlagDefinitionError] = useState<Error>();
  const [flagDefinitionResponse, setFlagDefinitionResponse] = useState<AxiosResponse>();

  const getTenantFeatureFlag = useCallback(
    (flagKey) => data!.find((tenantFeatureFlag) => tenantFeatureFlag.flagKey === flagKey), [data],
  );

  const getTenantFeatureFlags = useCallback(async () => {
    try {
      setLoading(true);
      const tenantFeatureFlags: ITenantFeatureFlagValues[] = [];
      const hashFlagValues = await getFeatureFlagValues(apiBaseUrl ?? '', accessToken, tenantId);
      const flagDefinitions: FeatureFlagDefinitionData[] = await getFeatureFlagDefinitions(apiBaseUrl ?? '', accessToken);
      flagDefinitions.forEach((flagdef) => {
        // TODO: Implement logic to support other type values
        if (flagdef.possibleValues.type === 'Boolean') {
          tenantFeatureFlags.push({
            flagKey: flagdef.flagKey,
            scope: 'Tenant',
            flagDefinition: flagdef,
            flagValue: hashFlagValues[flagdef.flagKey],
          });
        }
      });
      if (tenantFeatureFlags.length) {
        setResults(tenantFeatureFlags);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [accessToken, apiBaseUrl, tenantId]);

  const updateFeatureFlagValue = useCallback(
    (flagKey: string, value?: string, locked?: boolean) => {
      setFlagValueChange({
        flagKey,
        value,
        locked,
      });
      setFlagValueLoading(true);
      const apiPath = `${apiBaseUrl ?? ''}/config/api/v1/tenants/${tenantId}/featureFlags/flags/${flagKey}/scopes/Tenant`;
      putFlagValueData(apiPath, accessToken, value, locked)
        .then((response) => {
          setFlagValueResponse(response);
        })
        .catch((err) => {
          setFlagValueError(err);
        })
        .finally(() => {
          setFlagValueLoading(false);
        });
    },
    [accessToken, apiBaseUrl, tenantId],
  );

  const updateFeatureFlagDefinition = useCallback(
    (flagKey: string, defaultValue?: string, visibility?: FeatureFlagVisibility) => {
      setFlagDefinitionChange({
        flagKey,
        defaultValue,
        visibility,
      });
      setFlagDefinitionLoading(true);
      const apiPath = `${apiBaseUrl ?? ''}/config/privilege/api/v1/featureFlags/definitions/${flagKey}`;
      patchFlagDefinitionData(apiPath, accessToken, defaultValue, visibility)
        .then((response) => {
          setFlagDefinitionResponse(response);
        })
        .catch((err) => {
          setFlagDefinitionError(err);
        })
        .finally(() => {
          setFlagDefinitionLoading(false);
        });
    },
    [accessToken, apiBaseUrl],
  );

  const clearUpdateResponses = useCallback(
    () => {
      setFlagDefinitionResponse(undefined);
      setFlagValueResponse(undefined);
    },
    [],
  );

  const clearUpdateErrors = useCallback(
    () => {
      setFlagDefinitionError(undefined);
      setFlagValueError(undefined);
    },
    [],
  );

  useEffect(() => {
    getTenantFeatureFlags();
  }, [getTenantFeatureFlags]);

  return {
    tenantFeatureFlagsData: data,
    error,
    loading,
    featureFlagValueUpdateStateHandler: {
      updateFeatureFlagValue,
      flagValueChange,
      flagValueLoading,
      flagValueError,
      flagValueResponse,
    },
    featureFlagDefinitionUpdateStateHandler: {
      updateFeatureFlagDefinition,
      flagDefinitionChange,
      flagDefinitionLoading,
      flagDefinitionError,
      flagDefinitionResponse,
    },
    getTenantFeatureFlag,
    clearUpdateResponses,
    clearUpdateErrors,
  };
}
