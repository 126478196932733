import * as React from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles } from '../../styles';
import { Listbox as BbUiListbox } from '../../Listbox';
import { useComboboxContext } from '../Combobox.context';
import type { ComboboxGroup, ComboboxOption, ComboboxProps } from '../Combobox.types';
import { useListboxHandlers } from '../../Listbox/useListboxHandlers';

export const useStyles = makeStyles(<
  Option extends ComboboxOption,
  Group extends ComboboxGroup<Option>
>() =>
  createStyles({
    list: {
      margin: 0,
      padding: 0,
      '& > li > span:hover': {
        textDecoration: 'underline',
      },
    },
    listbox: {
      maxHeight: '50vh',
      overflowY: 'auto',
      '& > li:first-child': {
        // Round top corners when not searchable
        borderRadius: ({ isSearchable }: Partial<ComboboxProps<Option, Group>>) =>
          !isSearchable ? '1px 1px 0 0' : undefined,
      },
      '& > li:last-child': {
        // Round bottom corners
        borderRadius: '0 0 1px 1px',
      },
    },
  })
);

export const Listbox = <Option extends ComboboxOption, Group extends ComboboxGroup<Option>>() => {
  const {
    comboboxProps,
    filteredOptions,
    components,
    listboxHasFocus,
    isOptionSelected,
    selectOption,
    deselectOption,
    searchString,
    setMenuIsOpen,
  } = useComboboxContext<Option, Group>();
  const { id, isMulti, strings, showSelectedOptions, isLoading, isSearchable, ListboxProps } =
    comboboxProps;
  const { MenuItem, NoResultsMenuItem, LoadingResultsMenuItem, MenuListSubheader } = components;
  const { noResults, loadingResults } = strings;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const classes = useStyles({ isSearchable });
  const { listRef, handleOnMouseUp, handleOnKeyDown } = useListboxHandlers({
    hasFocus: listboxHasFocus,
    isMulti,
    isOptionSelected,
    selectOption,
    deselectOption,
    onEnter: () => {
      setMenuIsOpen(false);
    },
    onEscape: () => {
      setMenuIsOpen(false);
      if (inputRef.current) {
        inputRef.current?.focus();
      }
    },
    onTab: () => {
      setMenuIsOpen(false);
      inputRef.current?.focus();
    },
  });

  function filterSelected(option: Option) {
    return !(!showSelectedOptions && isOptionSelected(option));
  }

  function filterGroups(optionOrGroup: Option | Group) {
    if ('options' in optionOrGroup) {
      const groupOptions = optionOrGroup.options.filter(filterSelected);

      return groupOptions.length ? { ...optionOrGroup, options: groupOptions } : ([] as never[]);
    }

    return filterSelected(optionOrGroup) ? optionOrGroup : ([] as never[]);
  }

  function renderOption(optionOrGroup: Option | Group, groupLabel?: string) {
    if ('options' in optionOrGroup) {
      // The problem with using optionOrGroup.label is there could be a space in the label
      const subHeaderId = `combobox-subheader-${
        optionOrGroup.id ?? optionOrGroup.label.replace(/ /g, '')
      }`;

      return (
        <li key={subHeaderId} role="presentation">
          <ul role="group" aria-labelledby={subHeaderId} className={classes.list}>
            <MenuListSubheader
              id={subHeaderId}
              key={optionOrGroup.label}
              group={optionOrGroup}
              role="presentation"
            >
              {optionOrGroup.label}
            </MenuListSubheader>
            {optionOrGroup.options.map((option) => renderOption(option, optionOrGroup.label))}
          </ul>
        </li>
      );
    }

    const option = optionOrGroup as Option;

    return (
      <MenuItem
        key={`${groupLabel}-${option.value}`}
        option={option}
        // MUI (ButtonBase) calls onClick through onKeyDown without offering an escape hatch - Use onMouseUp to bypass.
        onMouseUp={() => handleOnMouseUp(option)}
        onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>) => handleOnKeyDown(event, option)}
        selected={isOptionSelected(option)}
        aria-selected={isOptionSelected(option)}
      >
        {option.label}
      </MenuItem>
    );
  }

  return (
    <BbUiListbox
      ref={listRef}
      id={`${id}-menu`}
      aria-labelledby={`${id}-label`}
      aria-multiselectable={isMulti}
      {...ListboxProps}
      // Declare after spread to merge consumer styles with internal styles.
      className={classnames(classes.list, classes.listbox, ListboxProps?.className)}
    >
      {isLoading && loadingResults && (
        <LoadingResultsMenuItem role="option">{loadingResults}</LoadingResultsMenuItem>
      )}
      {!isLoading && !filteredOptions.length && searchString && (
        <NoResultsMenuItem role="option">{noResults(searchString)}</NoResultsMenuItem>
      )}
      {!isLoading && filteredOptions.flatMap(filterGroups).map((option) => renderOption(option))}
    </BbUiListbox>
  );
};

export default Listbox;
