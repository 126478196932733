import * as React from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles } from '../../styles';
import { Chip } from '../../Chip';
import type { MultiValueProps, ComboboxOption } from '../Combobox.types';

export const useStyles = makeStyles(() => createStyles({
  root: {
    pointerEvents: 'none',
    margin: '2px 2px 2px 0',
    padding: '2px 0'
  },
}));

export const MultiValue = <Option extends ComboboxOption>(props: MultiValueProps<Option>) => {
  const { option, className, ...other } = props;
  const classes = useStyles();

  return <Chip className={classnames(classes.root, className)} label={option.label} {...other} />;
};

export default MultiValue;
