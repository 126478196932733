import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const BadgeOverrides = (theme: Theme): Overrides => ({
  MuiBadge: {
    badge: {
      // Background color is set in the main component

      color: theme.palette.brand.contrastText,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    dot: {
      // Position is set in the main component

      borderRadius: '50%',
    },
  },
});
