import { IconButton } from '../../IconButton';
import { Menu } from '../../Menu';
import { MenuItem } from '../../MenuItem';
import type { Components, ButtonProps, MenuItemProps, MenuProps } from '../Breadcrumbs.types';

export const components: Components<MenuProps, MenuItemProps, ButtonProps> = {
  Menu,
  MenuItem,
  Button: IconButton,
};

export const defaultComponents = <
  TMenuProps extends MenuProps,
  TMenuItemProps extends MenuItemProps,
  TButtonProps extends ButtonProps,
>(
  overrideComponents?: Partial<Components<TMenuProps, TMenuItemProps, TButtonProps>>
): Components<TMenuProps, TMenuItemProps, TButtonProps>  => ({
    ...components as Components<TMenuProps, TMenuItemProps, TButtonProps>,
    ...overrideComponents,
});
