import { createStyles, makeStyles, Theme, rgba } from '../../styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContent: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },
    iconContainer: {
      flex: '0 0 auto',
      width: '16px',
      marginRight: '12px',
    },
    menuItem: {}, // Define classname
    selected: {
      textDecoration: 'underline',
    },
    multiSelected: {
      // Match existing specificity to override
      '$menuItem&': {
        backgroundColor: 'transparent',
        color: 'inherit',
        '&:hover, &:focus': {
          backgroundColor: rgba(theme.palette.primary.main, 0.06), // Value from ListItem.overrides.ts
          color: 'inherit',
        },
      },
    },
  })
);
