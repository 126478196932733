import { getColor, createStyles, makeStyles, Theme } from '../styles';

export const useStyles = makeStyles((theme: Theme) => {
  const backgroundColor = theme.palette.type === 'light' ? getColor('background.b3', 'dark') : getColor('background.b3', 'light');

  return createStyles({
    snackbarContentRoot: {
      backgroundColor,
    },
    actionLink: {
      textAlign: 'end',
      flex: 1,
    },
    linkMessage: {
      fontWeight: theme.typography.fontWeightSemiBold,
      textTransform: 'capitalize',
      marginLeft: '16px',
    },
    content: {
      display: 'inline-flex',
    },
    messageIcon: {
      marginRight: '8px',
      alignSelf: 'center',
    },
  });
});

export default useStyles;
