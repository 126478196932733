// This is a generated file. Any changes made directly in this file will be overwritten.
import * as React from 'react';
import { createSvgIcon } from '..';

export const Trash = createSvgIcon(
  {
    LeftToRight: (
      <React.Fragment>
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 4l2-3h4l2 3M2 5l1 10h10l1-10M1 4h14M8 7v5M11 7l-.5 5M5 7l.5 5"
        />
      </React.Fragment>
    ),
  },
  'small'
);
