import axios from 'axios';

import { FeatureFlagVisibility } from '../common.types';
import { FeatureFlagDefinitionData } from '../../feature-flag-definition/use-feature-flag-definitions.types';
import { IFeatureFlagValueData } from '../../tenant-feature-flag/use-tenant-feature-flags.types';

const CHECK_STATUS_DELAY_MS = 1000;

export async function fetchFlagsData(apiPath: string, accessToken: string, cursor = '', limit: number | null = null) {
  const reqConfig = {
    params: { cursor, ...(limit) && { limit } },
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const response = await axios.get(apiPath, reqConfig);
  return response.data;
}

export async function fetchAllFlagsData(apiPath: string, accessToken: string, cursorParam = '', limitParam: number | null = null) {
  let allFlagsData: (FeatureFlagDefinitionData | IFeatureFlagValueData)[] = [];
  let nextCursor = null;
  let cursor = cursorParam;
  let limit = limitParam;
  do {
    /* eslint-disable no-await-in-loop */
    const flagsData = await fetchFlagsData(apiPath, accessToken, cursor, limit);
    allFlagsData = allFlagsData.concat(flagsData.results);
    nextCursor = flagsData.cursor;
    if (flagsData.cursor) {
      cursor = flagsData.cursor;
    }
    if (flagsData.limit) {
      limit = flagsData.limit;
    }
  } while (nextCursor);
  return allFlagsData;
}

export function putFlagValueData(apiPath: string, accessToken: string, value?: string, locked?: boolean) {
  const authConfig = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const body = JSON.stringify({ value, locked });
  return axios.put(apiPath, body, authConfig);
}

export function patchFlagDefinitionData(apiPath: string, accessToken: string, defaultValue?: string, visibility?: FeatureFlagVisibility) {
  const authConfig = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const body = JSON.stringify({ defaultValue, visibility });
  return axios.patch(apiPath, body, authConfig);
}

function putTenantsFlagValueApi(apiBaseUrl: string | undefined, accessToken: string, region: string, tenants: string[], flagKey: string, scope: string, value?: string, locked?: boolean) {
  const apiPath = `${apiBaseUrl ?? ''}/config/privilege/api/v1/regions/${region}/featureFlags/flags/${flagKey}/scopes/${scope}`;
  const authConfig = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const body = {
    flagValue: {
      value, locked,
    },
    tenantList: tenants,
  };

  return axios.put(apiPath, body, authConfig);
}

async function getTenantsFlagValueResponse(apiPath: string, accessToken: string) {
  const authConfig = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  return axios.get(apiPath, authConfig);
}

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const putFlagValueInAllTenants = async (apiBaseUrl: string | undefined, accessToken: string, region: string, tenants: string[], executionArnId: string) => {
  const apiPath = `${apiBaseUrl ?? ''}/config/privilege/api/v1/regions/${region}/featureFlags/flags/status/${executionArnId}`;
  let results = null;
  let status = null;

  do {
    /* eslint-disable no-await-in-loop */
    const response = await getTenantsFlagValueResponse(apiPath, accessToken);
    status = response.data.status;
    if (status === "SUCCEEDED") {
      results = JSON.parse(response.data.output);
    } else if ((status === "FAILED") || (status === "TIMED_OUT") || (status === "ABORTED")) {
      throw new Error(status);
    } else {
      await sleep(CHECK_STATUS_DELAY_MS);
    }
  } while (status === "RUNNING");
  return results;
};

export const putTenantsFlagValue = async (accessToken: string, flagKey: string, region: string, tenants: string[], scope: string,
  value?: string, locked?: boolean, apiBaseUrl?: string) => new Promise((resolve, reject) => {
  putTenantsFlagValueApi(apiBaseUrl, accessToken, region, tenants, flagKey, scope, value, locked)
    .then((response) => {
      const { executionArnId } = response.data;
      return putFlagValueInAllTenants(apiBaseUrl, accessToken, region, tenants, executionArnId);
    })
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export default putTenantsFlagValue;
