import * as React from 'react';
import Tab from '../Tab';
import { IIconTabProps } from '../Tab.types';
import { Tooltip } from '../../Tooltip';

const IconTab = React.forwardRef((
  props: IIconTabProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const { ariaLabel, label, tooltipPlacement, ...other } = props;

  if (typeof label !== 'string' && !ariaLabel) {
    throw new Error('ariaLabel must be defined for non-string labels');
  }

  return (
    <Tooltip
      title={label as NonNullable<React.ReactNode>}
      placement={tooltipPlacement || 'bottom'}
    >
      <Tab
        {...other}
        ref={ref}
        aria-label={typeof label === 'string' ? label : ariaLabel}
      />
    </Tooltip>
  );
});

export default IconTab;
