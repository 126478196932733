import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import { Palette } from '@material-ui/core/styles/createPalette';

const placeholder = {
  color: 'currentColor',
  opacity: 0.6,
};

export const InputBaseOverrides = (theme: Theme, palette: Palette): Overrides => ({
  MuiInputBase: {
    root: {
      fontSize: theme.typography.fontSizeDefault,
      '&$adornedStart': {
        paddingLeft: '12px',
        '& > div': {
          color: theme.palette.text.secondary,
        },
      },
      '&$adornedEnd': {
        paddingRight: '12px',
        '& > div': {
          color: theme.palette.text.secondary,
        },
      },
    },
    input: {
      boxSizing: 'border-box',
      padding: '1px 12px 0',
      minHeight: '38px',
      '&::-webkit-input-placeholder': placeholder,
      '&::-moz-placeholder': placeholder, // Firefox 19+
      '&::-ms-input-placeholder': placeholder, // Edge
      'label[data-shrink=false] + $formControl &': {
        '&::-webkit-input-placeholder': { opacity: undefined },
        '&::-moz-placeholder': { opacity: undefined }, // Firefox 19+
        '&::-ms-input-placeholder': { opacity: undefined }, // Edge
        '&:focus::-webkit-input-placeholder': { opacity: undefined },
        '&:focus::-moz-placeholder': { opacity: undefined }, // Firefox 19+
        '&:focus::-ms-input-placeholder': { opacity: undefined }, // Edge
      },
      '&$inputAdornedStart': {
        paddingLeft: 0,
      },
      '&$inputAdornedEnd': {
        paddingRight: 0,
      },
    },
    multiline: {
      padding: 0,
    },
    inputMultiline: {
      padding: '10px 10px 0',
      lineHeight: '17px',
    },
  },
});
