import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { createTheme as createMuiTheme } from './theme';

export type ThemeType = 'light' | 'dark' | Theme;

let lightTheme: Theme;
let darkTheme: Theme;

const createTheme = (type: string) => {
  if (type === 'light') {
    return lightTheme = lightTheme || createMuiTheme({ palette: { type: 'light' } });
  }
  return darkTheme = darkTheme || createMuiTheme({ palette: { type: 'dark' } });
};

export const getTheme = (themeType: ThemeType) => {
  if (typeof themeType === 'string') {
    return createTheme(themeType);
  }
  return themeType;
};

type AllKeysOfUnion<T> = T extends {} ? keyof T: never;
type PaletteColor<T> = {[key in AllKeysOfUnion<T>]: T[key]};

export const getColor = (colorString: string, themeType: ThemeType) => {
  const theme = getTheme(themeType);
  const colorArr = colorString.split('.');
  const color = colorArr[0] as keyof typeof theme.palette;
  const palette = theme.palette[color];

  if (typeof palette === 'object') {
    const variant = colorArr[1] as AllKeysOfUnion<typeof palette>;
    const paletteVariant = (palette as PaletteColor<typeof palette>)[variant];
    if (typeof paletteVariant === 'string') {
      return paletteVariant;
    }
  }

  if (process.env.NODE_ENV === 'test') {
    return `Color ${colorString} not found.`;
  }

  throw Error(`Color ${colorString} not found.`);
};
