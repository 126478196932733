import * as React from 'react';
import classnames from 'classnames';
import { throttle } from 'lodash';
import { useResizeDetector } from 'react-resize-detector';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { offScreenContent } from '@bb-ui/styling/dist/styles/mixins';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { IconButton } from '../IconButton';
import { ChevronLeft } from '../../internal/icons/small/ChevronLeft';
import { ChevronRight } from '../../internal/icons/small/ChevronRight';
import { useTheme } from '../styles';

export const DEFAULT_SCROLL_DELTA = 400;

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  scrollArea: {
    display: 'flex',
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  offScreenContent: {
    ...offScreenContent,
  },
}));

export interface NavigationListScrollWrapperProps {
  children: React.ReactNode;
  className?: string;
  scrollLeftButtonAriaLabel: string;
  scrollRightButtonAriaLabel: string;
}

export const calculateScrollValue = (direction: string, rtlDirectionMultiplier: number, offsetWidth: number) => {
  const delta = Math.min(offsetWidth, DEFAULT_SCROLL_DELTA);
  const chevronDirectionMultiplier = direction === 'left' ? -1 : 1;
  return delta * chevronDirectionMultiplier * rtlDirectionMultiplier;
};

export const animateScroll = (to: number, scrollElement: HTMLElement) => {
  const easeInOutSin = (time: number) => (1 + Math.sin(Math.PI * time - Math.PI / 2)) / 2;
  const duration = 300;
  const from = scrollElement.scrollLeft;
  let start: number | null = null;
  const step = (timestamp: number) => {
    if (start === null) {
      start = timestamp;
    }
    const time = Math.min(1, (timestamp - start) / duration);
    scrollElement.scrollLeft = easeInOutSin(time) * (to - from) + from; // eslint-disable-line no-param-reassign
    if (time >= 1) {
      return;
    }
    requestAnimationFrame(step);
  };
  requestAnimationFrame(step);
};

const NavigationListScrollWrapper = React.forwardRef((
  props: NavigationListScrollWrapperProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const classes = useStyles(props);
  const { children, className, scrollLeftButtonAriaLabel, scrollRightButtonAriaLabel } = props;
  const scrollAreaRef = React.useRef<HTMLDivElement>(null);

  const [chevronLeftVisible, setChevronLeftVisibility] = React.useState(false);
  const [chevronRightVisible, setChevronRightVisibility] = React.useState(false);
  const theme = useTheme();
  const isRtl = theme.direction === 'rtl';
  const rtlDirectionMultiplier = isRtl ? -1 : 1;

  const setScrollButtonVisibility = React.useCallback(() => {
    const scrollElement = scrollAreaRef.current;
    if (scrollElement) {
      const scrollLeft = scrollElement.scrollLeft * rtlDirectionMultiplier;
      const visibleArea = scrollLeft + scrollElement.offsetWidth;
      setChevronRightVisibility(visibleArea < scrollElement.scrollWidth);
      setChevronLeftVisibility(scrollLeft > 0);
    }
  }, [rtlDirectionMultiplier, scrollAreaRef]);

  const move = React.useCallback((direction: string) => {
    const scrollElement = scrollAreaRef.current;
    if (scrollElement) {
      const scrollTo = scrollElement.scrollLeft + calculateScrollValue(direction, rtlDirectionMultiplier, scrollElement.offsetWidth);
      animateScroll(scrollTo, scrollElement);
    }
  }, [rtlDirectionMultiplier, scrollAreaRef]);

  React.useEffect(() => {
    const scrollElement = scrollAreaRef.current;
    if (!scrollElement) {
      return undefined;
    }
    setScrollButtonVisibility();
    scrollElement.addEventListener('scroll', throttle(setScrollButtonVisibility, 100));
    return () => {
      scrollElement.removeEventListener('scroll', throttle(setScrollButtonVisibility, 100));
    };
  }, [isRtl, setScrollButtonVisibility, scrollAreaRef]);

  useResizeDetector({ onResize: setScrollButtonVisibility, targetRef: scrollAreaRef, refreshMode: 'throttle', refreshRate: 1000 });

  return (
    <div className={classnames(classes.root, className)} ref={ref}>
      <IconButton
        onClick={() => move('left')}
        disabled={!chevronLeftVisible}
        className={classnames({ [classes.offScreenContent]: !chevronLeftVisible })}
        aria-label={isRtl ? scrollRightButtonAriaLabel : scrollLeftButtonAriaLabel}
      >
        <ChevronLeft />
      </IconButton>
      <div className={classes.scrollArea} ref={scrollAreaRef}>
        { children }
      </div>
      <IconButton
        onClick={() => move('right')}
        disabled={!chevronRightVisible}
        className={classnames({ [classes.offScreenContent]: !chevronRightVisible })}
        aria-label={isRtl ? scrollLeftButtonAriaLabel : scrollRightButtonAriaLabel}
      >
        <ChevronRight />
      </IconButton>
    </div>
  );
});

export default NavigationListScrollWrapper;
