import * as React from 'react';
import { default as MuiLinearProgress, LinearProgressProps as uiLinearProgressProps } from '@material-ui/core/LinearProgress';
import { Typography } from '../../Typography';
import { getColor, Theme, makeStyles, createStyles } from '../../styles';
import { ProgressAriaLabelProps, ProgressLabelProps } from '../Progress.types';

export interface BaseLinearProgressProps extends Omit<uiLinearProgressProps, 'id' | 'ref'> {
}

export interface LinearProgressAriaLabelProps extends BaseLinearProgressProps, ProgressAriaLabelProps {
}

export interface LinearProgressLabelProps extends BaseLinearProgressProps, ProgressLabelProps {
}

export type LinearProgressProps = LinearProgressAriaLabelProps | LinearProgressLabelProps;

export const useStyles = makeStyles((theme: Theme) => {
  const errorBackground = theme.palette.type === 'light' ? theme.palette.error.light : theme.palette.error.dark;
  const progressBorder = theme.palette.type === 'light' ? getColor('text.primary', 'dark') : getColor('text.primary', 'light');

  return createStyles({
    root: {
      border: `1px solid ${progressBorder}`,
      width: '100%',
    },
    colorComplete: {
      backgroundColor: getColor('success.main', theme),
    },
    colorCompleteBar: {
      backgroundColor: getColor('success.main', theme),
      border: `2px solid ${theme.palette.success.main}`,
    },
    colorError: {
      backgroundColor: errorBackground,
    },
    colorErrorBar: {
      backgroundColor: getColor('error.main', theme),
      border: `2px solid ${theme.palette.error.main}`,
    },
    valueLabel: {
      fontWeight: 'bold',
      textAlign: 'center',
      width: 'inherit',
    },
  });
});

const colorPrimaryStateColor = (props: LinearProgressProps, styles: ReturnType<typeof useStyles>) => {
  const { error, value } = props;
  if (error) {
    return styles.colorError;
  }
  if (value === 100) {
    return styles.colorComplete;
  }
  return undefined;
};

const barColorPrimaryStateColor = (props: LinearProgressProps, styles: ReturnType<typeof useStyles>) => {
  const { error, value } = props;
  if (error) {
    return styles.colorErrorBar;
  }
  if (value === 100) {
    return styles.colorCompleteBar;
  }
  return undefined;
};

export const LinearProgress = (
  props: LinearProgressProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const { classes, id, maxValue, minValue, value, variant, error, ...withAriaProps } = props;
  const { ariaLabel, ...withLabelProps } = withAriaProps as LinearProgressAriaLabelProps;
  const { label, labelProps, ...other } = withLabelProps as LinearProgressLabelProps;
  const styles = useStyles(props);
  const colorPrimary = colorPrimaryStateColor(props, styles);
  const barColorPrimary = barColorPrimaryStateColor(props, styles);

  if (label && !id) {
    throw new Error(`The id must be supplied when using an on-screen label`);
  }

  if (variant === 'determinate') {
    return (
      <>
        <MuiLinearProgress
          ref={ref}
          variant={variant}
          className={styles.root}
          classes={{ colorPrimary, barColorPrimary, ...classes }}
          value={value}
          aria-valuemin={minValue ?? 0}
          aria-valuemax={maxValue ?? 100}
          aria-valuetext={label}
          aria-label={ariaLabel}
          {...other}
        />
        <Typography
          component="p"
          variant="h4"
          className={styles.valueLabel}
          {...labelProps}
        >
          {label}
        </Typography>
      </>
    );
  }

  return (
    <>
      <MuiLinearProgress
        ref={ref}
        className={`${styles.root}`}
        classes={{ colorPrimary, barColorPrimary, ...classes }}
        value={undefined}
        role="status"
        aria-valuemin={undefined}
        aria-valuemax={undefined}
        aria-label={!label ? ariaLabel : undefined}
        aria-labelledby={label ? `linear-text-${id}` : undefined}
        {...other}
      />
      { label ? (
        <Typography
          id={`linear-text-${id}`}
          component="p"
          variant="h4"
          className={styles.valueLabel}
          {...labelProps}
        >
          {label}
        </Typography>
      ) : null }
    </>
  );
};

const LinearProgressRef = React.forwardRef(LinearProgress) as (
  props: LinearProgressProps & { ref?: React.Ref<HTMLDivElement> }
) => ReturnType<typeof LinearProgress>;

export default LinearProgressRef;
