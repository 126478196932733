import * as React from 'react';
import { Check } from '../../../internal/icons/small/Check';
import { makeStyles, createStyles, Theme, rgba } from '../../styles';
import { ListboxOption } from '../../Listbox/components';
import { ListItemIcon } from '../../ListItemIcon';
import { useComboboxContext } from '../Combobox.context';
import type { ComboboxOption, MenuItemProps, ComboboxGroup } from '../Combobox.types';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContent: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },
    iconContainer: {
      flex: '0 0 auto',
      width: '16px',
      marginRight: '12px',
    },
    menuItem: {}, // Define classname
    selected: {
      textDecoration: 'underline',
    },
    multiSelected: {
      // Match existing specificity to override
      '$menuItem&': {
        backgroundColor: 'transparent',
        color: 'inherit',
        '&:hover, &:focus': {
          backgroundColor: rgba(theme.palette.primary.main, 0.06), // Value from ListItem.overrides.ts
          color: 'inherit',
        },
      },
    },
  })
);

export const MenuItem = <Option extends ComboboxOption, Group extends ComboboxGroup<Option>>(
  props: MenuItemProps<Option>
) => {
  const { children, option, selected, replaceContent = false, ...other } = props;
  const { comboboxProps } = useComboboxContext<Option, Group>();
  const { isMulti } = comboboxProps;
  const classes = useStyles(props);

  return (
    <ListboxOption
      classes={{
        root: classes.menuItem,
        selected: isMulti ? classes.multiSelected : classes.selected,
      }}
      value={option.value}
      selected={selected}
      isMulti={isMulti}
      {...other}
    >
      {replaceContent && children}
      {!replaceContent && (
        <>
          {isMulti && (
            <ListItemIcon className={classes.iconContainer}>{selected && <Check />}</ListItemIcon>
          )}
          <span className={classes.itemContent}>{children}</span>
        </>
      )}
    </ListboxOption>
  );
};

export default MenuItem;
