import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { default as MuiChip } from '@material-ui/core/Chip';
import { ChipProps } from './Chip.types';
import { Clear } from '../../internal/icons/small/Clear';
import { IconButton } from '../IconButton';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  deleteButton: {
    minHeight: 'auto',
    minWidth: 'auto',
    height: '29px',
    margin: '-5px auto -5px 10px',
  },
}));

export const Chip = React.forwardRef((
  props: ChipProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const { clickable, deleteLabel, deleteButtonProps, label, onDelete, onClick, ...other } = props;
  const classes = useStyles(props);
  const isClickable = clickable || onClick;

  return (
    <MuiChip
      // MUI sets role as button for clickable and deletable chips, we only want it set when clickable
      role={isClickable ? 'button' : undefined}
      tabIndex={isClickable ? 0 : -1}
      onDelete={onDelete}
      onClick={onClick}
      clickable={clickable}
      deleteIcon={<></>}
      label={
        <>
          {label}
          {onDelete &&
            <IconButton
              className={classes.deleteButton}
              aria-label={deleteLabel || 'delete'}
              onClick={event => onDelete(event)}
              {...deleteButtonProps}
            >
              <Clear />
            </IconButton>
          }
        </>
      }
      ref={ref}
      {...other}
    />
  );
});

export default Chip;
