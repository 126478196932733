import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';

export const SvgIconOverrides = (theme: Theme): Overrides => ({
  MuiSvgIcon: {
    fontSizeSmall: {
      fontSize: 16,
    },
    fontSizeLarge: {
      fontSize: 38,
    },
  },
});
