import * as React from 'react';
import classnames from 'classnames';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import { makeStyles, createStyles, Theme } from '../../styles';
import { useComboboxContext } from '../Combobox.context';
import { Typography } from '../../Typography';
import type { ComboboxGroup, ComboboxOption, ComboboxProps } from '../Combobox.types';

export const useStyles = makeStyles(<
  Option extends ComboboxOption,
  Group extends ComboboxGroup<Option>,
>(theme: Theme) => {
  const placeholderDisabled = theme.palette.type === 'light' ? theme.palette.text.primary : theme.palette.background.b7;

  return createStyles({
    combobox: {
      marginRight: '12px',
    },
    input: {
      cursor: 'pointer',
      // Positioning context provided by inputWrap (See Combobox.tsx)
      position: 'absolute',
      height: '100%',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      opacity: 0,
    },
    disabled: {
      cursor: 'default',
    },
    placeholder: {
      // match default input padding
      marginLeft: '12px',
      color: ({ disabled }: Partial<ComboboxProps<Option, Group>>) => (
        disabled ? placeholderDisabled : theme.palette.text.primary
      ),
    },
  });
});

export const Combobox = (props: InputBaseComponentProps) => {
  const { id, inputRef, placeholder, className, disabled, value: inputValue, ...inputProps } = props;
  const { menuIsOpen, hasFocusWithin, hasValue, comboboxProps } = useComboboxContext();
  const { floatingLabel } = comboboxProps;
  const classes = useStyles({ disabled });
  const showPlaceholder = placeholder && floatingLabel ? !hasValue && hasFocusWithin : !hasValue;

  return (
    <div
      id={`${id}-combobox`}
      className={classes.combobox}
    >
      {showPlaceholder && (
        <Typography className={classes.placeholder}>{placeholder}</Typography>
      )}
      <input
        id={id}
        ref={inputRef}
        role="combobox"
        aria-haspopup="listbox"
        aria-controls={menuIsOpen ? `${id}-menu`: undefined}
        aria-expanded={menuIsOpen}
        aria-disabled={disabled}
        className={classnames(
          className,
          classes.input,
          { [classes.disabled]: disabled },
        )}
        disabled={disabled}
        // Note: readonly and aria-readonly are provided through the inputProps spread.
        {...inputProps}
        // Include aria-describedby after spread to avoid override. This provides a readable value for screen-readers.
        aria-describedby={`combobox-value-${id}`}
      />
    </div>
  );
};

export default Combobox;
