import * as React from 'react';

export interface ITabPanelProps {
  panelId: string;
  ariaLabelledby: string;
}

export const TabPanel: React.FunctionComponent<ITabPanelProps> = (props) => {
  const { ariaLabelledby, panelId, children } = props;

  return (
    <div
      role="tabpanel"
      tabIndex={0}
      aria-labelledby={ariaLabelledby}
      id={panelId}
    >
      {children}
    </div>
  );
};

export default TabPanel;
