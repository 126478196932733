import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormLabel,
  FormControl,
  DialogActions,
  DefaultButton,
  PrimaryButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  makeStyles,
  createStyles,
  Theme,
} from '@bb-ui/react-library';
import { FieldsetProps, LegendProps } from "@bb-ui/react-library/dist/components/RadioGroup/RadioGroup.types";
import { FeatureFlagVisibility } from "hooks/common/common.types";
import {
  FeatureFlagDefinitionData,
  FeatureFlagPossibleValuesOption,
} from "hooks/feature-flag-definition/use-feature-flag-definitions.types";
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { getBundle, ILocaleProps } from "../../lib/locales";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  dialogTitle: {
    alignItems: 'start',
  },
  section: {
    marginTop: theme.spacing(2),
  },
  sectionDescription: {
    marginLeft: theme.spacing(3.25),
  },
  metadataInfoIcon: {
    position: 'relative',
    top: theme.spacing(2),
    left: theme.spacing(1),
  },
  sectionWarning: {
    marginBottom: theme.spacing(1),
    color: '#e86826',
  },
  radioButton: {
    marginLeft: theme.spacing(-1),
  },
}));

export interface FeatureFlagEditDialogProps extends ILocaleProps {
  isDialogOpen: boolean;
  dialogToggle: (value: boolean) => void;
  flagDefinition: FeatureFlagDefinitionData;
  updateFeatureFlagDefinition: (flagKey: string, defaultValue?: string, clearFlagValues?: boolean, visibility?: FeatureFlagVisibility) => void;
}

export const FeatureFlagEditDialog: React.FC<FeatureFlagEditDialogProps> = (props) => {
  const classes = useStyles(props);
  const Fieldset = FormControl as React.ForwardRefExoticComponent<FieldsetProps>;
  const Legend = FormLabel as React.ForwardRefExoticComponent<LegendProps>;
  const { flagDefinition, isDialogOpen, dialogToggle, updateFeatureFlagDefinition, locale } = props;
  const bundle = getBundle(locale);
  const [flagNewDefaultValue, setFlagNewDefaultValue] = React.useState<string>(flagDefinition.defaultValue);
  const [clientAdminPermission, setClientAdminPermission] = React.useState<string>(flagDefinition.visibility.visible ? "edit" : "none");
  const [clearFlagValues, setClearFlagValues] = React.useState<string>("disabled");

  const { possibleValues } = flagDefinition;

  function handleOnClose() {
    dialogToggle(false);
  }

  function handleDefaultValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFlagNewDefaultValue(event.target.value);
  }

  function handleClientAdminPermissionChange(event: React.ChangeEvent<HTMLInputElement>) {
    setClientAdminPermission(event.target.value);
  }

  function handleClearFlagValuesChange(event: React.ChangeEvent<HTMLInputElement>) {
    setClearFlagValues(event.target.value);
  }

  function handleOnSubmit() {
    const clearValues = clearFlagValues === "enabled";
    const newVisibleSetting = clientAdminPermission === "edit";
    const visibilityPayload: FeatureFlagVisibility = {
      visible: newVisibleSetting,
    };
    // If global visiblity isn't changing, preserve criteria.
    if (flagDefinition.visibility.criteria && flagDefinition.visibility.visible === newVisibleSetting) {
      visibilityPayload.criteria = flagDefinition.visibility.criteria;
    }
    updateFeatureFlagDefinition(flagDefinition.flagKey, flagNewDefaultValue, clearValues, visibilityPayload);
    dialogToggle(false);
  }

  const defaultValueOptions = (
    <Fieldset>
      <Legend />
      <RadioGroup
        hasCustomLegend
        onChange={handleDefaultValueChange}
        value={flagNewDefaultValue}
        aria-label={bundle.flagDefinitionDefaultValueAriaLabel}
        name="default-value-selection"
      >
        {possibleValues.type.toLowerCase() === "list" &&
          possibleValues.options?.map((option: FeatureFlagPossibleValuesOption) => {
            const value = option.value.toLowerCase();
            return (
              <FormControlLabel className={classes.radioButton} key={value} value={value} control={<Radio />} label={value} />
            );
          })
        }
        { possibleValues.type.toLowerCase() !== "list" &&
          <>
            <FormControlLabel className={classes.radioButton} value="false" control={<Radio />} label={bundle.off} />
            <FormControlLabel className={classes.radioButton} value="true" control={<Radio />} label={bundle.on} />
          </>
        }
      </RadioGroup>
    </Fieldset>
  );

  const overrideValuesOptions = (
    <Fieldset>
      <Legend />
      <RadioGroup
        hasCustomLegend
        onChange={handleClearFlagValuesChange}
        value={clearFlagValues}
        aria-label={bundle.flagDefinitionOverrideAriaLabel}
        name="override-values-selection"
      >
        <FormControlLabel className={classes.radioButton} value="disabled" control={<Radio />} label={bundle.no} data-testid="flag-definition-edit-dialog-override-disable" />
        <FormControlLabel className={classes.radioButton} value="enabled" control={<Radio />} label={bundle.yes} data-testid="flag-definition-edit-dialog-override-enable" />
      </RadioGroup>
    </Fieldset>
  );

  const clientPermissionOptions = (
    <Fieldset>
      <Legend />
      <RadioGroup
        hasCustomLegend
        onChange={handleClientAdminPermissionChange}
        value={clientAdminPermission}
        aria-label={bundle.flagDefinitionClientPermissionsAriaLabel}
        name="client-permission-selection"
      >
        <FormControlLabel className={classes.radioButton} value="none" control={<Radio />} label={bundle.none} />
        <FormControlLabel className={classes.radioButton} value="edit" control={<Radio />} label={bundle.canEdit} />
      </RadioGroup>
    </Fieldset>
  );

  const flagDefinitionLabel = flagDefinition.labels.find((element: any) => element.locale.toLowerCase() === "en_us")?.label;

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleOnClose}
      aria-label={bundle.featureFlagDefEditDialogueAriaLabel}
      data-testid="flag-definition-edit-dialog"
    >
      <DialogTitle onClose={handleOnClose} className={classes.dialogTitle} aria-label={bundle.featureFlagDefEditDialogueTitleAriaLabel}>
        {bundle.featureFlagDefEditDialogueTitle.replace('{flagName}', flagDefinitionLabel ?? '')}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h4" className={classes.section}> { bundle.globalStatus } </Typography>
        <div id="defaultValueControl">
          { defaultValueOptions }
        </div>

        <Typography variant="h4" className={classes.section}> { bundle.flagDefinitionEditDialogueOverrideLabel } </Typography>
        <div id="overrideValuesControl">
          { overrideValuesOptions }
        </div>
        {clearFlagValues === "enabled" &&
          <Typography data-testid="flag-definition-value-warning" variant="body2" className={classes.sectionWarning}>
            {bundle.featureFlagDefEditDialogueDefaultValueWarning.replace('{flagName}', flagDefinitionLabel ?? '')}
          </Typography>
        }
        <hr className={ classes.section } />
        <div id="visibilityLabel">
          <Typography variant="h4" className={classes.section}> { bundle.globalPermissions } </Typography>
        </div>
        <div id="permissionControl">
          { clientPermissionOptions }
        </div>
        <Typography variant="body2" color="textSecondary" className={classes.sectionDescription}> {bundle.flagDefinitionEditDialogueClientPermNote} </Typography>
      </DialogContent>

      <DialogActions>
        <DefaultButton id="cancel-flag-definition-edit-btn" onClick={handleOnClose}>{bundle.cancel}</DefaultButton>
        <PrimaryButton id="submit-flag-definition-edit-btn" onClick={handleOnSubmit}>{bundle.save}</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
